<template>
    <div>

        <div class="container">
            <h3 class="text-primary text-center text-lg-left  font-lato-bold mb-3">
                Applications of F-Biotic
            </h3>
            <div class="row">
                <div class="col-lg-4">
                    <h5 class="text-primary text-center text-lg-left font-lato-bold mb-3 pt-4 mt-lg-6">Primary Applications</h5>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="fl-y lg-fl-a-s fl-a-c">
                        <img src="../../../../assets/products/milk.png" class=" mt-4">
                        <p class="font-lato-bold mt-3">Protein Supplements/Health premixes</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="fl-y lg-fl-a-s fl-a-c">
                        <img src="../../../../assets/products/powder.png" class="">
                        <p class="font-lato-bold">RTD Sachets</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <h5 class="text-primary text-center text-lg-left font-lato-bold mb-3 pt-4 mt-6">Secondary Applications</h5>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="fl-y lg-fl-a-s fl-a-c">
                        <img src="../../../../assets/products/cookies.png" class=" mt-4">
                        <p class="font-lato-bold ml-lg-2">Cookies</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="fl-y lg-fl-a-s fl-a-c">
                        <img src="../../../../assets/products/bread.png" class="">
                        <p class="font-lato-bold ml-lg-2">Bread</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ApplicationsF-biotic'
};
</script>

<style scoped>

</style>
