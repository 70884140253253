<template>
    <product-page-template title="Green Tea Extract" :image="productImg" price="899">
        <template #info>
            <p class="mt-3">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aliquid dolorem dolores
                impedit! Adipisci consequuntur et exercitationem, facilis iste modi, nesciunt odit quia sint
                tempora ullam velit veniam voluptates. At debitis doloribus ex exercitationem fugiat quis
                soluta tenetur voluptates. A adipisci animi dolorem esse facilis fuga harum incidunt, ipsa
                magni molestiae natus odit pariatur possimus praesentium quam quis sed velit voluptatum.
                Amet asperiores consequuntur dicta dignissimos distinctio eius facere fugit hic iusto minus
                necessitatibus nobis officia, quasi velit voluptatibus. A adipisci aliquid atque blanditiis,
            </p>
        </template>
    </product-page-template>
</template>

<script>
import ProductPageTemplate from '@components/ProductPageTemplate';
import img from '../../../assets/products/product-1.jpg';

export default {
    name: 'GreenTeaExtract',
    components: { ProductPageTemplate },
    data () {
        return {
            productImg: img
        };
    }
};
</script>

<style scoped>

</style>
