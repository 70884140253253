<!--<template>-->
<!--<div class="py-5">-->
<!--    <div class="container">-->
<!--        <div class="row">-->
<!--            <div class="col-12 col-lg-2" v-for="(item,i) in items" :key="i">-->
<!--                <img :src="item.img" class="img-fluid" alt="">-->
<!--                <p class="mt-2 text-center fs-2 text-primary">{{ item.text }}</p>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<!--</template>-->

<script>
import img1 from '../../assets/features/Sport_Nutrition_Circle.png';
import img2 from '../../assets/features/Healthy Aging@2x.png';
import img3 from '../../assets/features/Cognitive Health Circle@2x.png';
import img4 from '../../assets/features/Womens and Mens Health@2x.png';
import img5 from '../../assets/features/Weight Management.svg';
import img6 from '../../assets/features/Immune Health Circle@2x.png';

export default {
    name: 'FeaturesSection',
    data () {
        return {
            items: [
                {
                    img: img1,
                    text: 'Sport Nutrition'
                },
                {
                    img: img2,
                    text: 'Healthy Aging'
                },
                {
                    img: img3,
                    text: 'Cognitive Health'
                },
                {
                    img: img4,
                    text: 'Womens & Mens Health'
                },
                {
                    img: img5,
                    text: 'Metabolic & Weight Management'
                },
                {
                    img: img6,
                    text: 'Immune Health'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
