<template>
    <div>
        <product-page-template title="CURO-DIS 95™" :image="img3" price="
        The World’s First Water Dispersible Curcuminoids 95%" price-classes="ml-lg-n6 pr-lg-4">
            <template #title>
                <div class="sm-fl-x sm-fl-j-c md-d-block">
                    <img src="../assets/curo-dis/Curo-dis95-logo-1.svg" class="icon-md">
                </div>
            </template>
            <template #info>
                <div class="ml-lg-n6 pr-lg-6">
                    <p class="mt-3 txt-align">
                        In the functional food sector, curcumin is a natural ingredient that provides a distinctive color
                        and flavor profile, as well as having potential health benefits. Arishina’s Curo-Dis 95™ is the
                        world’s first water-dispersible curcuminoids with 95% strength suited ideally for the functional
                        beverage market with higher convenience and improved delivery methodology. Naturally occurring
                        curcuminoids are soluble in organic solvents but poorly soluble in water. Curo-Dis 95™ is an
                        innovative product that has been produced using a proprietary ‘emulsion crosslinking‘ method which
                        ensures 95% of curcuminoids have water dispersibility & stability. This water-dispersible curcumin
                        is the best choice for using curcuminoids in food, drink, and health supplement.

                    </p>
                </div>
            </template>
        </product-page-template>
        <!--        Video-->
        <div>
            <div class="container py-lg-6 pb-5">
                <div class="fl-x-cc mx-lg-6">
                    <iframe class="w-100p i-frame-video-lg" src="https://www.youtube.com/embed/T6gUrbHjTZs" title="Comparison of Curo-Dis 95 with standard turmeric extract"
                            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductPageTemplate from '@components/ProductPageTemplate';
// import img from '../../../assets/products/product-1.jpg';
import img2 from '../../../assets/products/curcumin.jpg';
import img3 from '../assets/curo-dis/curo-dis-product.jpg';
import img4 from '../../../assets/products/curostain.jpg';

export default {
    name: 'CUR-DIS-95',
    components: { ProductPageTemplate },
    data () {
        return {
            productImg: img2,
            img3: img3,
            img4: img4
        };
    }
};
</script>

<style scoped>

</style>
