<template>
    <div class="mt-n6 mt-lg-0">
        <inner-banner image="bg-about" text-color="white" heading="" height=""/>
        <section class="">
            <div class="container py-5">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-center fs-9 font-weight-bold mb-5">Blogs</h2>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12 mb-4" v-for="(item,i) in items" :key="i">
                        <article-card
                            :img="item.img"
                            :heading="item.heading"
                            :content="item.content"
                            :link="item.link"
                            :source="item.source"
                            :invert-img=" i===1"
                        ></article-card>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12 mb-4">
                        <article-card
                            :img="agrospectrumData.img"
                            :heading="agrospectrumData.heading"
                            :content="agrospectrumData.content"
                            :link="agrospectrumData.link"
                            :source="agrospectrumData.source"
                            :invert-img="false"
                        >
                            <template #img>
                                <router-link :to="agrospectrumData.link" class="mb-0 bg-2">
                                    <div class="card-min-h px-3 fl-x-cc">
                                        <img :src="agrospectrumData.img" alt="" class="img-fluid mb-0">
                                    </div>
                                </router-link>
                            </template>
                        </article-card>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12 mb-4">
                        <article-card
                            :img="chronicleData.img"
                            :heading="chronicleData.heading"
                            :content="chronicleData.content"
                            :link="chronicleData.link"
                            :source="chronicleData.source"
                            :invert-img="false"
                        >
                            <template #img>
                                <router-link :to="chronicleData.link" class="mb-0 bg-2">
                                    <div class="card-min-h px-3 fl-x-cc">
                                        <img :src="chronicleData.img" alt="" class="img-fluid mb-0">
                                    </div>
                                </router-link>
                            </template>
                        </article-card>
                    </div>
                    <div class="col-12">
                        <h3 class="text-center fs-7 font-weight-bold mb-5 mt-4">Videos</h3>
                    </div>
                </div>
                <div class="row fl-j-c">
                    <div class="col-md-6">
                        <div class="">
                            <iframe class="w-100p i-frame-video-md" src="https://www.youtube.com/embed/Y4-qzkCwoOo" title="Women Empowerment by Arishina Lfe Sciences"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                    <!--                    <div class="col-md-6">-->
                    <!--                        <div class="mt-5 mt-md-0">-->
                    <!--                            <iframe class="w-100p i-frame-video-md" src="https://www.youtube.com/embed/Y4-qzkCwoOo" title="Women Empowerment by Arishina Lfe Sciences"-->
                    <!--                                    frameborder="0"-->
                    <!--                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"-->
                    <!--                                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="col-md-6">-->
                    <!--                        <div class="mt-5">-->
                    <!--                            <iframe class="w-100p i-frame-video-md" src="https://www.youtube.com/embed/Y4-qzkCwoOo" title="Women Empowerment by Arishina Lfe Sciences"-->
                    <!--                                    frameborder="0"-->
                    <!--                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"-->
                    <!--                                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import InnerBanner from '@components/InnerBanner';
import ArticleCard from '../../components/ArticleCard';
import img1 from '../../assets/img/logo/logo-businessline.svg';
import img2 from '../../assets/img/logo/nia-logo-white.svg';
import img3 from '../../assets/img/logo/logo-scaled.jpg';
import img4 from '../../assets/img/logo/logo-nutraceutical.svg';
import agrospectrumImg from '../../assets/img/logo/logo-agrospectrum.png';
import img6 from '../../assets/img/logo/DeccanChronicle_Logo.jpg';

export default {
    name: 'MediaMainPage',
    components: { ArticleCard, InnerBanner },
    data () {
        return {
            agrospectrumData: {
                img: agrospectrumImg,
                heading: 'Arishina Life Sciences teams up with Rainforest Alliance to eliminate pesticide contamination of spices',
                content: 'Kerala-based Arishina Life Sciences- manufacturers of spices and botanical extracts has resorted to certifying its supply chain with the ‘Rain Forest Alliance’ as a move to promote sustainable agricultural practices and fight pesticide contamination of herbs and spices.',
                link: '/news-page-agrospectrum/',
                source: 'https://agrospectrumindia.com/'
            },
            chronicleData: {
                img: img6,
                heading: 'Mitigating contamination of spices with rainforest alliance certification',
                content: 'Kochi: High pesticide residues in spices especially turmeric and chilly have been a grave concern and impediment to the dietary and food supplement industry.',
                link: '/news-page-deccan-cronicle/',
                source: 'https://www.deccanchronicle.com/'
            },
            items: [
                {
                    img: img1,
                    heading: 'Arishina Life Sciences gets Rainforest Alliance certification',
                    content: 'Kochi-based Arishina Life Sciences, which manufactures spices and botanical extracts, has received the Rainforest Alliance certification for its supply chain.',
                    link: '/news-businessline/',
                    source: 'www.thehindubusinessline.com'
                },
                {
                    img: img2,
                    heading: 'Indian botanical supplier Arishina Life Sciences addresses pesticides, traceability challenges',
                    content: 'The company’s supply chain of the turmeric that it uses to extract curcumin, has been certified by the Rainforest Alliance, an international non-governmental organisation, for its conformance with the alliance’s Sustainable Agriculture Standard.',
                    link: '/news-page-nutragradient/',
                    source: 'https://www.nutraingredients-asia.com/'
                },
                {
                    img: img3,
                    heading: 'Arishina Life Sciences mitigates pesticide contamination of spices with Rain Forest Alliance certification',
                    content: 'Kerala-based Arishina Life Sciences, manufacturers of spices and botanical extracts, have resorted to certifying its supply chain with the ‘Rainforest Alliance’ to promote sustainable agricultural practices and fight pesticide contamination of herbs and spices. ',
                    link: '/news-page-nufffoods-spectrum/',
                    source: 'https://nuffoodsspectrum.in/'
                },
                {
                    img: img4,
                    heading: 'Arishina earns rainforest alliance certification against pesticide',
                    content: 'Kerala-based Arishina Life Sciences, manufacturers of spices and botanical extracts, have resorted to certifying its supply chain with the ‘Rainforest Alliance’ to promote sustainable agricultural practices and fight pesticide contamination of herbs and spices. ',
                    link: '/news-page-nutraceutical-business/',
                    source: 'https://nutraceuticalbusinessreview.com/'
                }
                // {
                //     img: img5,
                //     heading: 'Arishina Life Sciences teams up with Rainforest Alliance to eliminate pesticide contamination of spices',
                //     content: 'Kerala-based Arishina Life Sciences- manufacturers of spices and botanical extracts has resorted to certifying its supply chain with the ‘Rain Forest Alliance’ as a move to promote sustainable agricultural practices and fight pesticide contamination of herbs and spices.',
                //     link: '/news-page-agrospectrum/',
                //     source: 'https://agrospectrumindia.com/'
                // },
                // {
                //     img: img6,
                //     heading: 'Mitigating contamination of spices with rainforest alliance certification',
                //     content: 'Kochi: High pesticide residues in spices especially turmeric and chilly have been a grave concern and impediment to the dietary and food supplement industry.',
                //     link: '/news-page-deccan-cronicle/',
                //     source: 'https://www.deccanchronicle.com/'
                // }
            ]
        };
    }
};
</script>

<style scoped lang="scss">
    .card-min-h {
        position: relative;
        height: 14rem;

        /*img {*/
        /*    width: 100% !important;*/
        /*    height: 100% !important;*/
        /*}*/
    }
</style>
