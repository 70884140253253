<template>
    <div class="container py-5">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="text-primary font-lato-bold  mb-3">CUR-LIV FOR RUMINANTS</h4>
                <p class=" font-lato-medium mb-3">An effective feed supplement to enhance the over-all
                    immunity of cows, buffaloes , sheeps etc</p>
                 <p class="txt-align">Cur-Liv is a unique product by Arishina Life Sciences enriched with upto 10% of
                    curcuminoids , upto 10 % of ar-turmerones , manufactured using Arishina’s proprietary Natural
                    Complex Sandwich technology .</p>
                <p class="txt-align pt-2 mb-2">Cur-Liv is a cost effective
                    feed additive solution to address all the heath needs of Ruminants.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h4 class="text-primary fs-lg-4 font-lato-bold pt-4">MECHANISM OF ACTION OF CUR-LIV ON RUMINANTS </h4>
                <p class="pt-3">Here's a general overview of how curcumin may work to improve cow health:</p>
                <ol class="pl-5 pb-2">
                    <li class="mb-2 font-lato-semibold pt-2">Anti-Inflammatory Effects:
                    </li>

                    <ul class=" txt-align  mb-3">
                        <li><span class="font-lato-semibold">Inhibition of Inflammatory Mediators :&nbsp;</span>
                            Curcumin is known for its potent anti-inflammatory
                            properties. By inhibiting the activity of pro-inflammatory molecules and enzymes, such as
                            cyclooxygenase-2 (COX-2) and nuclear factor-kappa B (NF-κB), it can help reduce
                            inflammation.
                            In cows, this may benefit conditions related to inflammation, such as joint issues or
                            infections.
                        </li>
                    </ul>
                    <li class="mb-2 font-lato-semibold pt-2">Antioxidant Properties :</li>
                    <ul class=" txt-align  mb-3">
                        <li><span class="font-lato-semibold">Scavenging Free Radicals :&nbsp;</span>
                            Curcumin's antioxidant activity can help protect cells and tissues from oxidative damage
                            caused by free radicals. In cows,
                            this could support overall health and potentially mitigate oxidative stress associated with
                            various stressors.
                        </li>
                    </ul>
                    <li class="mb-2 font-lato-semibold pt-2">Potential Antimicrobial Effects :</li>
                    <ul class=" txt-align  mb-3">
                        <li><span class="font-lato-semibold"> Potential Antimicrobial Effect :&nbsp; </span>Curcumin has
                            demonstrated antimicrobial properties, particularly
                            against bacteria. This might help reduce the risk of
                            bacterial infections in cows, including mastitis, respiratory infections, and digestive
                            issues.
                        </li>
                    </ul>
                    <li class="mb-2 font-lato-semibold pt-2"> Immune Modulation :</li>
                    <ul class=" txt-align  mb-3">
                        <li><span class="font-lato-semibold"> Regulation of Immune Responses :&nbsp; </span>Curcumin has
                            the
                            potential to modulate the immune system. By balancing immune responses,
                            it may help cows better respond to infections or other health challenges.
                        </li>
                    </ul>
                    <li class="mb-2 font-lato-semibold pt-2">Gastrointestinal Health :</li>
                    <ul class=" txt-align  mb-3">
                        <li><span class="font-lato-semibold"> Gut Microbiota Modulation :&nbsp; </span> Emerging
                            research
                            suggests that
                            curcumin may positively influence gut health by
                            modulating the gut microbiota. A healthy gut can enhance nutrient absorption and overall
                            health in cows.
                        </li>
                    </ul>
                    <li class="mb-2 font-lato-semibold pt-2">Mitigation of Stress:</li>
                    <ul class=" txt-align  mb-3">
                        <li><span class="font-lato-semibold"> Stress Reduction:&nbsp; </span> In cows,
                            stress can have a significant impact on health. Curcumin's anti-inflammatory and
                            antioxidant effects might help mitigate the physical effects of stress, promoting better
                            overall health and well-being.
                        </li>
                    </ul>
                    <li class="mb-2 font-lato-semibold pt-2">Potential Reduction in Antibiotic Use:</li>
                    <ul class=" txt-align  mb-3">
                        <li><span class="font-lato-semibold">Alternative to Antibiotics :&nbsp;</span> As concerns
                            regarding
                            antibiotic use in livestock grow, curcumin could offer
                            an alternative or complementary approach to managing cow health issues without the use of
                            antibiotics.
                        </li>
                    </ul>
                    <li class="mb-2 font-lato-semibold pt-2"> Improved Lactation and Reproductive Health:</li>
                    <ul class=" txt-align  mb-3">
                        <li><span class="font-lato-semibold">Hormonal Balance :&nbsp;</span> Some studies suggest that
                            curcumin
                            may
                            have a positive effect on hormonal balance,
                            which could be beneficial for reproductive health and milk production in dairy cows.
                        </li>
                    </ul>
                </ol>
            </div>
            <div class="col-lg-6">
                <div class="fl-x fl-j-c mt-lg-5">
                    <img src="../assets/cur-liv/ruminants/mechanism-of-action.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-6 pt-3">
                <p>Mastitis is a serious infective disease that causes vast economic losses in dairy cows.
                    According to the survey of the dairy industry, about 40–50% of clinical mastitis are caused
                    by
                    E. coli . </p>
                <p class="pt-1">LPS is the main component of the cell wall of Gram-negative bacteria such as E.
                    coli.</p>
                <p class="pt-1">Curcumin has protective effect on mastitis and the anti-inflammatory mechanism
                    of
                    curcumin on
                    LPS-induced mastitis may be due to its ability to inhibit TLR4-mediated NF-κB
                    signaling pathways, thus making Curcumin a potential therapeutic agent against mastitis.</p>
            </div>
            <div class="col-md-6 pt-3">
                <img src="../assets/cur-liv/ruminants/mechanism-of-action-2.png" class="img-fluid">
            </div>
        </div>
        <h4 class="text-primary fs-lg-4 font-lato-bold pt-3 mt-5  mb-3">PUBLISHED STUDIES ON THE EFFECT OF CURCUMIN
            ON
            RUMINANTS</h4>
        <p class="pt-2">Curcumin has proven anti-inflammatory properties in ruminant feed (Fattori et al., 2015;
            Kim et al.,
            2013).</p>
        <p class="pt-2">In non-nursing calves (E3), the anti-inflammatory effect of dietary curcumin was evident
            due to
            reduction of lymphocytes and globulins. This anti-inflammatory action is attributed to the ability
            of curcumin to block nuclear factor kappa B (NF-Kb) and to reduce granuloma growth in response to
            various inflammatory
            stimuli without causing toxicity to the organism (Araujo and Leon, 2001).</p>
        <p class="pt-2">In dairy sheep and lambs, the anti-inflammatory response of curcumin in the diet was
            also confirmed
            by inhibition of pro-inflammatory cytokines and increase of anti-inflammatory cytokines
            (Cervantes-Valencia et al., 2016; Jaguezeski et al., 2018).</p>
        <h4 class="text-primary fs-lg-4 font-lato-bold pt-4  mb-3">HEALTH INDICATIONS OF CUR-LIV FOR
            RUMINANTS</h4>
        <div class="row">
            <div class="col-lg-7">
                <ol class="pl-3 txt-align">
                    <li>Mastitis</li>
                    <li class="pt-3">Inflammatory conditions such as joint health</li>
                    <li class="pt-3">Oxidative stress, environment stress etc</li>
                    <li class="pt-3">Digestive health</li>
                    <li class="pt-3">Respiratory conditions</li>
                    <li class="pt-3">Skin conditions such as dermatitis & wounds</li>
                    <li class="pt-3">Stress Mitigation</li>
                    <li class="pt-3">Reproductive health</li>
                    <li class="pt-3">Cancer prevention</li>
                    <li class="pt-3">Immune Modulation</li>
                </ol>
            </div>
            <div class="col-lg-5">
                <!--                <img src="../assets/products/curcumin.jpg" class="w-100p">-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CurlivRuminants'
};
</script>

<style scoped>

</style>
