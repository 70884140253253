<template>
    <div>

        <product-page-template title="" class="pb-3 pt-7" :image="img4"
                               price="Natural Anti- Oxidants">
            <template #info>

                <p class="mt-3">
                    Lipid oxidation is one of the most critical factors affecting the shelf life and the quality
                    attributes of the oil. Lipid oxidation causes undesirable changes in the taste, odor, texture,
                    flavor, and appearance of foods and also destroys fat-soluble vitamins. Moreover, the oxidative
                    degradation of lipids can damage biological membranes, enzymes, and proteins which may pose a direct
                    threat to human health. In recent decades, synthetic antioxidants, such as butylated hydroxyanisole
                    (BHA) and butylated hydroxytoluene (BHT), are extensively used in retarding the oxidative
                    degradation of lipids due to their high oxidative stability and low cost. However synthetic
                    anti-oxidants have safety concerns and prolonged use can cause health disorders. The benefits of
                    adding natural, plant-based antioxidants to lipids in order to prevent lipid oxidation have been
                    initiated in recent years. Natural antioxidants retard oxidative rancidity via the following
                    pathways:
                </p>
                <ol class="pl-5  mb-3">
                    <li>Capturing of free radicals
                    </li>
                    <li>Decomposing/deoxidizing peroxides</li>
                    <li>Scavenging oxygen</li>
                </ol>
                <p class="txt-align">Rosemary (Rosmarinus Officinalis L.) extract is popular as a natural antioxidant due to its strong
                    antioxidant capacity and fat-soluble property. The antioxidant capacity of Rosemary extract was
                    attributed to the presence of phenolic diterpenes that scavenge singlet oxygen, hydroxyl radicals,
                    and lipid peroxyl radicals, thereby preventing lipid oxidation. We have the expertise to produce the
                    purest quality Rosemary extracts of varying concentrations. </p>
                <p class="mb-2 text-primary  font-weight-medium">PRODUCT RANGE </p>
                <ul class=" pl-5">
                    <li>Carnosic Acid 5-50%</li>
                    <li>Rosmarinic Acid 5- 50%
                    </li>
                    <li>Ursolic Acid 5-50%</li>
                    <li>Carnosic Acid Oleoresin 25%</li>
                    <li> Rosemarinic Acid 25%</li>
                </ul>
            </template>
        </product-page-template>
    </div>
</template>

<script>
import ProductPageTemplate from '@components/ProductPageTemplate';
// import img from '../../../assets/products/product-1.jpg';
import img2 from '../../../assets/products/curcumin.jpg';
import img3 from '../../../assets/products/curdis.jpg';
import img4 from '../../../assets/products/NATURALANTIOXIDANT.jpg';

export default {
    name: 'Naturalanti-oxidant',
    components: { ProductPageTemplate },
    data () {
        return {
            productImg: img2,
            img3: img3,
            img4: img4

        };
    }
};
</script>

<style scoped>

</style>
