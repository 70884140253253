<template>
    <div class="" :class="{'py-lg-9': height === 'full'}">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div :class="{'fl-x-res-align-end': sideImage}">
                        <div class="w-20 fl-x-res-align-end">
<!--                            <img src="../../assets/home/Landing_page_grow.gif" class="img-fluid" alt="">-->
                            <slot name="image"></slot>
                        </div>
                        <div :class="[{'pl-lg-4': contentPadding}]">
                            <h4 class="mb-3 fs-lg-4 lh-16 text-primary font-lato-bold text-center-res text-uppercase">{{heading}}</h4>
                            <div class="text-center-res px-2 px-lg-0">
                                <slot name="content"></slot>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BaseShortInfo',
    props: {
        heading: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        },
        sideImage: {
            type: Boolean,
            default: false
        },
        contentPadding: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style scoped>
.lh-16{
    line-height: 1.6 !important;
}
</style>
