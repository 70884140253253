<template>
    <div class="container mt-5">
        <!--        <h3 class="text-primary font-lato-bold mb-3">Phyto-Res Technology</h3>-->
        <div class="sm-fl-x sm-fl-j-c md-d-block">
            <img src="./assets/phyto-res/phyto-res-logo.svg" class="icon-md">
        </div>
        <div class="row">
            <div class="col-lg-8">
                <h5 class="text-primary font-lato-bold mb-3 mt-4">BIOAVAILABILITY OF PHYTOEXTRACTS :</h5>
                <p class="txt-align">
                    The efficacy of therapeutic compounds is heavily influenced by their bioavailability, a critical parameter in drug development that determines the extent and
                    rate at
                    which an active ingredient becomes available at the site of action. Poor bioavailability is a common challenge in the drug industry, often resulting from
                    factors such
                    as poor absorption, instability of the drug, changing physiological environments of the stomach (Tingetal, 2014), and first-pass metabolism.
                </p>

                <p class="font-lato-bold font-weight-medium">FIRST-PASS METABOLISM</p>
                <p class="txt-align">
                    First-pass metabolism, also known as the first-pass effect, is a phenomenon wherein the concentration of a drug, once absorbed through the intestinal villi,
                    reaches the
                    liver via portal circulation and then to systemic circulation. In the liver, the drug undergoes several conjugation and reduction reactions. As a result, the
                    therapeutic efficacy of the drug can be compromised, requiring higher doses to achieve the desired effect, which in turn can increase the risk of side effects.
                </p>

                <p class="font-lato-bold font-weight-medium">CHANGING ENVIRONMENTS OF STOMACH</p>
                <p class="txt-align">
                    The different environments that affect the pharmacokinetics of drugs are the interaction of high activity of digestive enzymes and harsh acidic conditions in
                    the
                    stomach (MClements, Decker, Park, & Weiss, 2009). This not only reduces the bioavailability of the drug but also limits the options for oral administration,
                    which is
                    often the most convenient and preferred route of drug administration.
                </p>
            </div>
            <div class="col-lg-4">
                <div class="fl-x-cc h-100p">
                    <img src="./assets/phyto-res/bio-availability.png" alt="">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h4 class="text-primary font-lato-bold mb-3 mt-5">PHYTO-RES TECHNOLOGY</h4>
                <p class="txt-align">
                    Phyto-Res technology is a groundbreaking advancement in the field of drug delivery, developed by Arishina Life Sciences, to address the pervasive challenge of
                    poor
                    bioavailability in nutraceuticals. This innovative system harnesses the unique properties of natural resistant starch to encapsulate bioactive compounds,
                    thereby
                    revolutionizing the way drugs are delivered to the human body. The core of Phyto-Res technology lies in its ability to facilitate targeted gut delivery, a
                    crucial
                    aspect that sets it apart from conventional drug delivery methods.
                </p>
            </div>
            <div class="col-lg-8">
                <img src="./assets/phyto-res/phyto-res-technology.png" class="img-fluid" alt="">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <h5 class="text-primary fs-lg-4 font-lato-bold pt-4 fl-x fl-a-c">
                    WHAT IS PHYTO-RES TECHNOLOGY ?
<!--                    <div class="sm-fl-x sm-fl-j-c md-d-block ml-2">-->
<!--                        <img src="./assets/phyto-res/phyto-res-logo.svg" class="icon-sm">-->
<!--                    </div>-->
                </h5>
                <p class="txt-align">
                    Arishina’s Phyto-Res technology is based on the encapsulation of the bioactives with natural-resistant starch. Resistant starch is a prebiotic dietary fibre
                    that has
                    the property of reaching the gut directly by passing through the stomach. The resistant starch used by Arishina is derived from fruits like bananas using a
                    patented
                    technology. Gut drug delivery, particularly colonic drug delivery, is a specialised approach in pharmaceutical science that focuses on delivering drugs to the
                    colon
                    region of the gastro-intestinal tract. The attendant drug delivery benefits associated with lymphatic drug transport, since the colon has relatively low
                    enzymatic
                    activity and digestive secretions compared to the upper gastrointestinal tract, provide a more stable environment for drug absorption. This process involves
                    several key
                    steps:
                </p>

                <p class="font-lato-bold font-weight-medium">1. ENCAPSULATION</p>
                <p class="txt-align">
                    The encapsulation process is meticulously designed to ensure that the bioactives are uniformly distributed within the resistant starch matrix, forming a
                    protective
                    barrier that shields them from the harsh conditions of the upper gastrointestinal tract
                </p>

                <p class="font-lato-bold font-weight-medium">2. PROTECTION IN TRANSIT</p>
                <p class="txt-align">
                    Once encapsulated, the bioactive compounds are protected from degradation in the acidic environment of the stomach and from enzymatic breakdown in the small
                    intestine.
                    The resistant starch carrier remains intact during this transit, preventing the premature release of the bioactives. This protection is crucial for maintaining
                    the
                    stability and integrity of the compounds until they reach the targeted site of action in the gut.
                </p>
                <p class="font-lato-bold font-weight-medium">3. TARGETED RELEASE IN THE GUT</p>
                <p class="txt-align">
                    The key to the mechanism of action of Phyto-Res technology lies in the targeted release of the encapsulated bioactives in the colon, where the outer shell is
                    fermented
                    by the colonic microbiota. This targeted release ensures that the bioactive compounds are available at the site of action in the gut, where they can be absorbed
                    effectively and exert their therapeutic effects.
                </p>
                <p class="font-lato-bold font-weight-medium">4. ABSORPTION AND THERAPEUTIC ACTION</p>
                <p class="txt-align">
                    Once released, the bioactive compounds are absorbed through the colonic mucosa into the bloodstream. By avoiding first-pass metabolism, Phyto-Res technology
                    enhances
                    the bioavailability of the bioactives, leading to improved therapeutic efficacy.
                </p>
            </div>
            <div class="col-lg-4">
                <div class="fl-x-cc h-100p">
                    <img src="./assets/phyto-res/what-is-phyto-tech.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <h5 class="text-primary font-lato-bold mb-3 mt-5">WHAT MAKES PHYTO-RES TECHNOLOGY BETTER THAN OTHERS ?</h5>
                <p class="txt-align">
                    Targeted release and absorption in the gut and potential side effects, making Phyto-Res an advantageous delivery system for a wide range of therapeutic
                    applications.
                    Phyto-Res technology stands out from other drug delivery systems due to several key advantages:
                </p>
                <p class="font-weight-medium font-lato-bold">ENHANCED BIOAVAILABILITY :</p>
                <p class="txt-align">
                    By protecting bioactive compounds from degradation in the stomach and ensuring their targeted release in the gut, Phyto-Res technology significantly enhances
                    the
                    bioavailability of drugs. This is particularly beneficial for compounds with poor stability, as it allows for more effective absorption and therapeutic action.
                </p>

                <p class="font-weight-medium font-lato-bold">SUSTAINED RELEASE :</p>
                <p class="txt-align">
                    Phyto-Res technology provides a sustained release mechanism as the large intestine has long residence time, ensuring that drugs are released gradually over time
                    intestine. This can lead to more sustained therapeutic effects, reduced dosing frequency, and improved patient compliance.
                </p>

                <p class="font-weight-medium font-lato-bold">VERSATILITY :</p>
                <p class="txt-align">
                    The technology is adaptable to a wide range of bioactive compounds, including phyto-extracts, vitamins, minerals, and other APIs. This versatility makes it
                    suitable for
                    various therapeutic applications.
                </p>

                <p class="font-weight-medium font-lato-bold">NATURAL AND SAFE :</p>
                <p class="txt-align">
                    Resistant starch is a well-tolerated and safe dietary fibre that is already present in many foods. This makes Phyto-Res technology a more natural and
                    potentially safer
                    alternative to synthetic polymers used in other drug delivery systems.
                </p>

                <p class="font-weight-medium font-lato-bold">ENVIRONMENTAL SUSTAINABILITY :</p>
                <p class="txt-align">
                    The use of natural materials in Phyto-Res technology contributes to its environmental sustainability. Resistant starch is a renewable resource, and the
                    production
                    process is likely to have a lower environmental impact compared to the synthesis of synthetic polymers.
                </p>

                <p class="font-weight-medium font-lato-bold">RELATIVELY LOW DOSING :</p>
                <p class="txt-align">
                    Since the drug is directly delivered to the prominent and targeted site of absorption, therapeutic efficacy can be obtained at a relatively low dosage.
                </p>

                <p class="font-weight-medium font-lato-bold">TARGETED DELIVERY :</p>
                <p class="txt-align">
                    Targeted delivery of drug to colon also a significant drug delivery in the treatment of localised diseases of colon itself.
                </p>
            </div>
            <div class="col-lg-4">
                <div class="fl-x-cc h-100p">
                    <img src="./assets/phyto-res/phyto-res-what-makes-better-than-others.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>

        <h4 class="text-primary fs-lg-4 font-lato-bold pt-4  fl-x fl-a-c">PRODUCTS UNDER
            <div class="sm-fl-x sm-fl-j-c md-d-block ml-2 mt-n1">
                <img src="./assets/phyto-res/phyto-res-logo.svg" class="icon-md">
            </div>
        </h4>
        <ol class="pl-3 txt-align">
            <li> Curcu-Res ( Bio-available Curcumin)</li>
            <li class="pt-3">Ashwa-Res ( Bio-available Ashwagandha)</li>
            <li class="pt-3"> Cafe-Res ( Bio-available natural caffeine)</li>
            <li class="pt-3">Akba-Res ( Bio-available AKBA)</li>
            <li class="pt-3">Lute-Res ( Bio-available Lutein)</li>
        </ol>
        <iframe class="w-100p i-frame-video-lg my-5" src="https://www.youtube.com/embed/txMJ3NjrIIc" title="Phyto Res  Technology ; Maximize Absorption , Maximize Wellness"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
    </div>
</template>

<script>
export default {
    name: 'PhytoResTechnologyPage'
};
</script>

<style scoped>

</style>
