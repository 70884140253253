import { render, staticRenderFns } from "./F-biotic.vue?vue&type=template&id=015f397c&scoped=true&"
import script from "./F-biotic.vue?vue&type=script&lang=js&"
export * from "./F-biotic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "015f397c",
  null
  
)

export default component.exports