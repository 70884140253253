<template>
    <section class="pt-9 pb-6 bg-info-details">
        <!--        <div class="overlay-dark" v-if="districtInfo" @click="districtInfo = !districtInfo"></div>-->
        <div class="container">

            <div id="copy-text" class="row">
                <div class="col-12">
                    <div class="fl-te-c mb-5">
                        <h4 class="text-center text-uppercase oswald-600">Turmeric Powder</h4>
                        <!--                        <btn size="sm" icon="fa fa-caret-left" text="Back" @click="$emit('back')"/>-->
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mt-1">
                        <div class="w-100">
                            <img style="" src="../assets/products/product-1.jpg" alt=""
                                 class="round-3 img-fluid">
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="mt-lg-5">
                        <h4 class="text-primary">₹ 63.00</h4>
                        <p class="mt-3">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aliquid dolorem dolores
                            impedit! Adipisci consequuntur et exercitationem, facilis iste modi, nesciunt odit quia sint
                            tempora ullam velit veniam voluptates. At debitis doloribus ex exercitationem fugiat quis
                            soluta tenetur voluptates. A adipisci animi dolorem esse facilis fuga harum incidunt, ipsa
                            magni molestiae natus odit pariatur possimus praesentium quam quis sed velit voluptatum.
                            Amet asperiores consequuntur dicta dignissimos distinctio eius facere fugit hic iusto minus
                            necessitatibus nobis officia, quasi velit voluptatibus. A adipisci aliquid atque blanditiis,
                        </p>
                    </div>
                </div>
                <div class="col-12">
                    <!--                    <tab-switching></tab-switching>-->
                    <tabs align="center" class="c-tabs bs-4">
                        <tab title="Product Significance">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab accusantium commodi
                                debitis deserunt dolor eius error id iure, laborum nam nisi nobis non placeat provident,
                                quas quidem quis quos reprehenderit similique suscipit tenetur vitae. Alias commodi
                                cumque eius enim facere, facilis mollitia nisi officiis, sint unde, vero voluptate.
                                Quasi.
                            </p>
                        </tab>
                        <tab title="Quality Assurance">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab accusantium commodi
                                debitis deserunt dolor eius error id iure, laborum nam nisi nobis non placeat provident,
                                quas quidem quis quos reprehenderit similique suscipit tenetur vitae. Alias commodi
                                cumque eius enim facere, facilis mollitia nisi officiis, sint unde, vero voluptate.
                                Quasi.
                            </p>
                        </tab>
                        <tab title="Nutritional Facts">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ab accusantium commodi
                                debitis deserunt dolor eius error id iure, laborum nam nisi nobis non placeat provident,
                                quas quidem quis quos reprehenderit similique suscipit tenetur vitae. Alias commodi
                                cumque eius enim facere, facilis mollitia nisi officiis, sint unde, vero voluptate.
                                Quasi.
                            </p>
                        </tab>
                    </tabs>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
// import TabSwitching from '@components/products/TabsSwitching';
export default {
    name: 'ProductPage',
    components: {}
};
</script>

<style scoped>

</style>
