<template>
    <div class="b-1">
        <router-link :to="link" class="bg-2 text-decoration-none" target="_blank">
            <slot name="img">
                <router-link :to="link" class="mb-0 bg-2">
                    <div class="card-min-h px-3">
                        <img :src="img" alt="" :class="{'invert-img': invertImg}" class="img-fluid mb-0">
                    </div>
                </router-link>
            </slot>
            <short-section :heading="heading" :source="source" :author="author" :size="size">
                <template #content><p class="fs--2 txt-align"> {{ content }} </p></template>
            </short-section>

        </router-link>
    </div>
</template>
<script> import ShortSection from './ShortSection';

export default {
    name: 'ArticleCard',
    components: { ShortSection },
    props: {
        heading: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        },
        author: {
            type: String,
            default: ''
        },
        img: {
            type: Image
        },
        invertImg: {
            type: Boolean,
            default: false
        },
        content: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: '/'
        },
        nextLink: {
            type: String,
            default: '/'
        },
        prevLink: {
            type: String,
            default: '/'
        }
    }
}; </script>
<style lang="scss" scoped>
    .card-min-h {
        position: relative;
        height: 14rem;

        img {
            width: 100% !important;
            height: 100% !important;
        }
    }

    .invert-img {
        -webkit-filter: invert(100%); /* Safari/Chrome */
        filter: invert(100%);
    }
</style>
