<template>
    <div class="container mt-5">
        <h4 class="text-primary font-lato-bold  mb-3">CUR-LIV FOR POULTRY</h4>
        <p class=" font-lato-medium mb-3">An effective feed supplement to enhance the over-all immunity of poultry</p>
        <p class="txt-align">Cur-Liv is a unique product by Arishina Life Sciences enriched with upto 10% of
            curcuminoids , upto 10 % of ar-turmerones , manufactured using Arishina’s proprietary Natural
            Complex Sandwich technology .</p>
        <p class="txt-align pt-2 mb-2">Cur-Liv is a cost effective
            feed additive solution to address all the heath needs of poultry.</p>
        <div class="row">
            <div class="col-lg-6">
                <h4 class="text-primary fs-lg-4 font-lato-bold pt-4">
                    MECHANISM OF ACTION OF CUR-LIV ON POULTRY
                </h4>
                <p class="txt-align mt-3">
                    Here's a general overview of how curcumin may work to improve cow health:
                </p>
                <p class="txt-align pt-2">
                    The mechanisms of action of curcumin on enhanced poultry health are complex and can involve several biological pathways. Here are some of the key
                    mechanisms by which curcumin may promote poultry health:
                </p>
                <ol class="pl-5 txt-align">
                    <li class="font-lato-bold">Anti-Inflammatory Effects:</li>
                    <p class="pt-2">
                        Curcumin is known for its potent anti-inflammatory properties. Inflammation can be a significant challenge in poultry farming, leading to
                        various health issues. Curcumin may help reduce inflammation by inhibiting the activity of pro-inflammatory molecules and pathways, such as cytokines and
                        NF-kappaB.
                    </p>
                    <li class="pt-2 font-lato-bold">Antioxidant Activity:</li>
                    <p class="pt-2">
                        Curcumin is a powerful antioxidant, which means it can help neutralize harmful free radicals in the body. By reducing oxidative stress,
                        curcumin may protect poultry from various diseases and support overall health.
                    </p>

                    <li class="pt-2 font-lato-bold">Immunomodulation:</li>
                    <p class="pt-2">
                        Curcumin can modulate the immune system, enhancing the bird's ability to fight off infections and diseases. It can stimulate the production of
                        immune cells and promote their function, thus improving the bird's resistance to pathogens.
                    </p>
                    <li class="pt-2 font-lato-bold">Antimicrobial Properties:</li>
                    <p>
                        Curcumin has demonstrated antimicrobial activity against various bacteria, fungi, and viruses. This can be beneficial in preventing and
                        managing infections in poultry.
                    </p>
                    <li class="pt-2 font-lato-bold">Gut Health:</li>
                    <p class="pt-2">
                        The gut plays a crucial role in poultry health, as it is involved in digestion, nutrient absorption, and immune function. Curcumin may support gut
                        health by promoting a balanced gut microbiota and reducing gut inflammation.
                    </p>
                    <li class="pt-2 font-lato-bold">Anti-Coccidial Effects:</li>
                    <p class="pt-2">
                        Curcumin has been studied for its potential to combat coccidiosis, a common parasitic disease in poultry. It may help reduce the severity of
                        coccidial infections and lessen the need for conventional medications.
                    </p>
                    <li class="pt-2 font-lato-bold">
                        Stress Reduction:
                    </li>
                    <p class="pt-2">
                        Stress can have a negative impact on poultry health and productivity. Curcumin's anti-stress properties may help reduce the adverse effects of
                        environmental
                    </p>
                </ol>
            </div>
            <div class="col-lg-6">
                <div class="w-100p fl-x-cc my-4 mt-lg-5">
                    <img src="../assets/cur-liv/poultry/mechanism-of-action.png">
                </div>
            </div>
        </div>
        <h4 class="text-primary fs-lg-4 font-lato-bold pt-4  mb-3">
            PUBLISHED STUDIES ON THE EFFECT OF CURCUMIN ON POULTRY
        </h4>
        <p class="txt-align">
            Turmeric administration is beneficial to minimize inflammatory effects of vaccination and help growth performance in commercial broiler chickens.
        </p>
        <p class="txt-align pt-2">
            Curcumin can enhance broiler’s growth performance, behavioral patterns, and immunity by reducing oxidative stress and up-regulating the growth-related gene expressions
            of broilers under stressful conditions due to a high stocking density.
        </p>
        <p class="txt-align pt-2">
            The results of one study indicated that the supplementation of natural feed additives such Curcumin extract improves the immune status, FCR, digestibility, and overall
            growth performance of broiler and also proves their effectiveness against P. multocida compared with the control group.
        </p>
        <p class="txt-align pt-2">
            Curcumin also decreased abdominal fat and blood cholesterol level in blood diseases & may lead to the development of low-cholesterol chicken meat as demanded by
            health-conscious consumers.
        </p>
        <p class="txt-align pt-2">
            One study has demonstrated that curcumin supplementation on chickens has diminished the inhibitory effect of aflatoxin on liver and kidney and these compounds may
            provide protection against the toxic effects of aflatoxin on liver and kidney.
        </p>

        <h4 class="text-primary fs-lg-4 font-lato-bold pt-4  mb-3">
            HEALTH BENEFITS OF CUR-LIV ON POULTRY
        </h4>
        <div class="row pb-5">
            <div class="col-md-6">
                <ol class="pl-3 txt-align">
                    <li>Reduced Inflammation</li>
                    <li class="pt-3">Better growth performance</li>
                    <li class="pt-3">Better Immunity</li>
                    <li class="pt-3">Improved FCR</li>
                    <li class="pt-3">Decreased abdominal fat & blood cholesterol</li>
                    <li class="pt-3">Better protection against aflatoxin toxicity</li>
                    <li class="pt-3">Anti-coccidial action</li>
                </ol>
            </div>
            <div class="col-md-6 pt-3">
                <div class="w-100p fl-x-cc">
                    <img src="../assets/cur-liv/poultry/health-benefits.png" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AnimalNutritionPoultry'
};
</script>

<style scoped>

</style>
