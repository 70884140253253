<template>
<!--    <section v-if="dataLoading" class="about py-lg-9">-->
<!--        <div class="text-center pt-7 pb-7">-->
<!--            <loading-animation class="text-primary text-5x"/>-->
<!--        </div>-->
<!--    </section>-->
    <div>
        <div class="container pt-7">
            <!--            <base-breadcrumb :path="['News', details.news.title]"-->
            <!--                             class="    "></base-breadcrumb>-->
            <div class="ml-lg-5 mr-lg-8">
                <div class="row">
                    <div class="col-12">
                        <img src="../../assets/about/banner-2.jpg" alt="" class="article-img">
                        <div class="ml-3 mt-3">
                            <div class="mb-2">
                                <span class="text-secondary font-inter-bold text-uppercase fs--1 mr-2">
                                    Category
                                    </span>
                            </div>
                            <h6 class="font-weight-bold font-semi-bold fs-lg-9">Title</h6>
                            <p class="fs--1 mt-3 font-inter-medium align-justify">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur explicabo labore
                                rem vitae! Animi, aut cupiditate debitis dignissimos dolore dolorem esse iusto nulla
                                odio officia qui repellendus similique! Ab accusamus alias commodi eaque esse eum
                                expedita impedit laudantium molestiae nihil nostrum obcaecati porro repudiandae vel,
                                voluptatibus. Ab accusamus ad aspernatur assumenda, explicabo fuga laboriosam laudantium
                                minus, mollitia nobis porro, ullam vel vero? Eum exercitationem inventore tenetur veniam
                                veritatis. Accusantium ad cum cupiditate exercitationem inventore ipsam, itaque labore
                                nihil nulla omnis quasi qui quis vitae! Aliquid asperiores aspernatur atque cumque
                                delectus, illum inventore labore libero necessitatibus neque odio officiis pariatur
                                perferendis quibusdam quod suscipit tenetur, ullam unde veniam voluptate? Animi dolore
                                explicabo odit perspiciatis. Accusantium at blanditiis dolore doloribus, ducimus
                                expedita ipsam laboriosam libero molestiae non numquam odio ratione sunt temporibus
                                voluptate. Accusantium aperiam assumenda autem dicta error ipsa modi quo. Architecto
                                autem cumque dolorem, eos et explicabo facilis harum id incidunt inventore, ipsam labore
                                laudantium maxime minus molestiae mollitia odio omnis perspiciatis possimus repudiandae
                                suscipit temporibus vero, voluptas. Ad adipisci aliquid consectetur deleniti distinctio
                                dolore dolorem ea eaque eligendi eos eveniet expedita facilis laborum minus nam
                                necessitatibus, non numquam quas, qui quia quisquam quod reprehenderit sequi veniam vero
                                voluptatem voluptatum.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-5">
            <div class="container">
                <h3 class="fs-5 font-inter-medium mb-4">Related Articles</h3>
                <div class="row">
                    <div v-for="i in 3" :key="i" class="col-12 col-lg-4 col-md-6">
                        <article-card
                            img="https://images.unsplash.com/photo-1657214059264-99456d9aae24?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                            heading="Medlounges announces new wellness programme ‘Body Shaping and Contouring’"
                            content="Medlounges. founded by Dr. Joseph Thomas, follows a simple non-invasive body shaping procedure by involving pulsed ultrasound energy to destroy selective fat cells."
                            link="https://www.thehindubusinessline.com/news/medlounges-announces-new-wellness-programme-body-shaping-and-contouring/article37639011.ece"
                            source="The Hindu Businessline"
                        ></article-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import BaseBreadcrumb from '@components/ui/BaseBreadcrumb';
// import NewsCard from '@components/home/NewsCard';
// import SubFooter from '@components/SubFooter';
// import axios from 'secure-axios';
// import urls from '../../data/urls';
import ArticleCard from '@components/ArticleCard';

export default {
    name: 'NewsArticlePage',
    components: { ArticleCard },
    props: {
        categ: {
            type: String,
            default: 'All'
        },
        heading: {
            type: String,
            default: 'Heading'
        },
        content: {
            type: String,
            default: 'Content'
        }
    }
    // data () {
    //     return {
    //         details: '',
    //         id: this.$route.params.id,
    //         dataLoading: false
    //     };
    // }
    // mounted () {
    //     this.loadDetails();
    // },
    // methods: {
    //     async loadDetails () {
    //         this.dataLoading = true;
    //         const response = await axios.form(urls.website.newsDetails, { id: this.id });
    //         const json = response.data;
    //         if (json.error === false) {
    //             this.details = json.data;
    //         } else {
    //             this.$notify('Could not load data, Please try again later', 'Error', {
    //                 type: 'danger'
    //             });
    //         }
    //         this.dataLoading = false;
    //     }
    // }
};
</script>

<style scoped lang="scss">
.article-img {
    width: 100%;;
    position: relative;
    @media (min-width: 992px) {
        height: 60vh !important;
    }
}
</style>
