<template>
    <!--    <section v-if="dataLoading" class="about py-lg-9">-->
    <!--        <div class="text-center pt-7 pb-7">-->
    <!--            <loading-animation class="text-primary text-5x"/>-->
    <!--        </div>-->
    <!--    </section>-->
    <div>
        <div class="container pt-5">
            <!--            <base-breadcrumb :path="['News', details.news.title]"-->
            <!--                             class="    "></base-breadcrumb>-->
            <div class="ml-lg-5 mr-lg-8">
                <div class="row">
                    <div class="col-12">
                        <!--                        <img-->
                        <!--                            src="../../../assets/img/news/arishina-earns-rainforest-alliance-certification-against.jpeg"-->
                        <!--                            alt=""-->
                        <!--                            class="article-img">-->
                        <div class="ml-3 mt-3">
                            <h6 class="font-weight-bold font-semi-bold fs-lg-9">
                                Arishina Life Sciences gets Rainforest Alliance certification
                            </h6>
                            <div class="mb-2">
                                <span class="text-uppercase fs--2 mr-2">
                                    November 29, 2022 - Updated 12:42 pm IST
                                    </span>
                            </div>
                            <div class="mb-2">
                                <span class="text-secondary font-inter-bold text-uppercase fs--1 mr-2">
                                    BY BL KOCHI BUREAU
                                    </span>
                            </div>
                            <img class="img-fluid"
                                src="../../../assets/img/news/India-s-botanical-supplier-firm-addresses-pesticides-traceability-challenges.png"
                                alt="">
                            <p class="mt-3 font-inter-bold align-justify">
                                Pesticides contamination and traceability are among the top five concerns that
                                nutraceutical brands have when procuring botanicals, according to Kerala-based Arishina
                                Life Sciences, which has implemented a number of measures to mitigate these challenges.
                            </p>
                            <p class="mt-4">
                                The company’s supply chain of the turmeric that it uses to extract curcumin, has been
                                certified by the Rainforest Alliance, an international non-governmental organisation,
                                for its conformance with the alliance’s Sustainable Agriculture Standard.
                            </p>
                            <p class="mt-4">
                                The company has also implemented agricultural practices in line with the alliance’s
                                standard for its supply chain of chilli and marigold – which it uses to extract lutein.
                                It is expecting to receive Rainforest Alliance’s certification for the supply chains of
                                these two materials by this year.
                            </p>
                            <div class="mt-4">
                                Speaking to <em>NutraIngredients-Asia, </em>
                                <p>
                                    Smiju Devassy, managing director at Arishina Life Sciences, pointed out that
                                    pesticides contamination was one of the top challenges that the nutraceutical
                                    industry was facing.
                                </p>
                            </div>
                            <div class="mt-4">
                                <p>
                                    This is especially because contamination, which could be from pesticides, heavy
                                    metal such as chromium and mercury, packaging materials, or irrigation water, could
                                    not be eradicated once a batch of crops has been affected.
                                </p>
                            </div>
                            <p class="mt-4"><em>“It is very difficult, there is no mechanism to remove or vanish these
                                contaminations&nbsp;once
                                it is contaminated. The quality control has to be done at the farming level,” </em>Devassy&nbsp;said.
                            </p>
                            <p class="mt-4">To tackle these problems, the company has introduced best farming practices
                                to the small
                                holder farmers that it is working with.&nbsp;</p>
                            <p class="mt-4">These include training the farmers and educating them on the list of banned
                                pesticides
                                that are not to be used.</p>
                            <p class="mt-4">Others include training farmers to select good quality seeds, examine the
                                quality of
                                soil, and optimise the fertilisers.</p>
                            <p class="mt-4"><em>“All these come under farming practices, which give them sustainable
                                yield and they
                                can avoid inferior quality crops,” </em>he said.</p>
                            <p class="mt-4">The company is focusing its best practices on these three crops as they are
                                the firm’s
                                major portfolios and are grown in large quantity.</p>
                            <p class="mt-4">The company mainly sells its ingredients for the domestic market; or export
                                them to the
                                US or European markets.</p>
                            <h4 class="mt-4"><strong>Traceability </strong></h4>
                            <p class="mt-4">Another area of concern common among nutraceutical firms is the traceability
                                of the
                                ingredients.</p>
                            <p>To which, Devassy&nbsp;said the firm has established a GPS tagging system to each part of
                                the agricultural process, such as the seed variety, fertiliser, pesticide used, as well
                                as the quantity used.</p>
                            <p>It also provided the QR code for the final product sold in the consumer market.</p>
                            <p><em>“</em><em>Anybody can scan the QR code and it will give [information on where was
                                this manufactured, where did this happen, etc].”</em></p>
                            <h4 class="mt-4"><strong>Increasing the yield </strong></h4>
                            <p class="mt-3">Aside from contamination control and strengthening traceability, the company
                                has managed
                                to improve crop yield via the best practices.</p>
                            <p class="mt-3">For example, it has helped partnering farms improve the yield of turmeric from 2.5 metric
                                tonnes per acre to four metric tonnes per acre in a 10-month crop cycle.&nbsp;</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-5">
            <div class="container">
                <h3 class="fs-5 font-inter-medium mb-4">Related Articles</h3>
                <div class="row">
                    <div v-for="(item, i) in items" :key="i" class="col-12 mb-4 col-lg-4 col-md-6">
                        <article-card
                            :img="item.img"
                            :heading="item.heading"
                            :content="item.content"
                            :link="item.link"
                            :source="item.source"
                            :invert-img="false"
                        ></article-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import BaseBreadcrumb from '@components/ui/BaseBreadcrumb';
// import NewsCard from '@components/home/NewsCard';
// import SubFooter from '@components/SubFooter';
// import axios from 'secure-axios';
// import urls from '../../data/urls';
import ArticleCard from '@components/ArticleCard';
import img1 from '@/assets/img/logo/logo-businessline.svg';
import img3 from '@/assets/img/logo/logo-scaled.jpg';
import img4 from '@/assets/img/logo/logo-nutraceutical.svg';
import img5 from '@/assets/img/logo/logo-agrospectrum.png';
import img6 from '@/assets/img/logo/DeccanChronicle_Logo.jpg';
// import img2 from '@/assets/img/logo/nia-logo-white.svg';
// import img3 from '@/assets/img/logo/logo-scaled.jpg';
// import img4 from '@/assets/img/logo/logo-nutraceutical.svg';

export default {
    name: 'NewsPageNutraingredients',
    components: { ArticleCard },
    props: {
        categ: {
            type: String,
            default: 'All'
        },
        heading: {
            type: String,
            default: 'Heading'
        },
        content: {
            type: String,
            default: 'Content'
        }
    },
    data () {
        return {
            items: [
                {
                    img: img1,
                    heading: 'Arishina Life Sciences gets Rainforest Alliance certification',
                    content: 'Kochi-based Arishina Life Sciences, which manufactures spices and botanical extracts, has received the Rainforest Alliance certification for its supply chain.',
                    link: '/news-businessline/',
                    source: 'www.thehindubusinessline.com'
                },
                {
                    img: img3,
                    heading: 'Arishina Life Sciences mitigates pesticide contamination of spices with Rain Forest Alliance certification',
                    content: 'Kerala-based Arishina Life Sciences, manufacturers of spices and botanical extracts, have resorted to certifying its supply chain with the ‘Rainforest Alliance’ to promote sustainable agricultural practices and fight pesticide contamination of herbs and spices. ',
                    link: '/news-page-nufffoods-spectrum/',
                    source: 'https://nuffoodsspectrum.in/'
                },
                {
                    img: img4,
                    heading: 'Arishina earns rainforest alliance certification against pesticide',
                    content: 'Kerala-based Arishina Life Sciences, manufacturers of spices and botanical extracts, have resorted to certifying its supply chain with the ‘Rainforest Alliance’ to promote sustainable agricultural practices and fight pesticide contamination of herbs and spices. ',
                    link: '/news-page-nutraceutical-business/',
                    source: 'https://nutraceuticalbusinessreview.com/'
                },
                {
                    img: img5,
                    heading: 'Arishina Life Sciences teams up with Rainforest Alliance to eliminate pesticide contamination of spices',
                    content: 'Kerala-based Arishina Life Sciences- manufacturers of spices and botanical extracts has resorted to certifying its supply chain with the ‘Rain Forest Alliance’ as a move to promote sustainable agricultural practices and fight pesticide contamination of herbs and spices.',
                    link: '/news-page-agrospectrum/',
                    source: 'https://agrospectrumindia.com/'
                },
                {
                    img: img6,
                    heading: 'Mitigating contamination of spices with rainforest alliance certification',
                    content: 'Kochi: High pesticide residues in spices especially turmeric and chilly have been a grave concern and impediment to the dietary and food supplement industry.',
                    link: '/news-page-deccan-cronicle/',
                    source: 'https://www.deccanchronicle.com/'
                }
            ]
        };
    }
    // data () {
    //     return {
    //         details: '',
    //         id: this.$route.params.id,
    //         dataLoading: false
    //     };
    // }
    // mounted () {
    //     this.loadDetails();
    // },
    // methods: {
    //     async loadDetails () {
    //         this.dataLoading = true;
    //         const response = await axios.form(urls.website.newsDetails, { id: this.id });
    //         const json = response.data;
    //         if (json.error === false) {
    //             this.details = json.data;
    //         } else {
    //             this.$notify('Could not load data, Please try again later', 'Error', {
    //                 type: 'danger'
    //             });
    //         }
    //         this.dataLoading = false;
    //     }
    // }
};
</script>

<style scoped lang="scss">
.article-img {
    width: 100%;;
    position: relative;
    @media (min-width: 992px) {
        height: 60vh !important;
    }
}
</style>
