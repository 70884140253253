<template>
    <div>
        <product-page-template title="F-Biotic™" :image="img2" price="" design="design-a" :center-image="false">
            <template #title>
                <div class="sm-fl-x sm-fl-j-c md-d-block ml-lg-n5">
                    <img src="../../../../assets/products/f-biotic/f-biotic-logo-1.jpg" class="icon-lg">
                </div>
            </template>
            <template #info>
                <div id="#whatIsFbiotic" class="ml-lg-n8">
                    <div class="mb-3 font-weight-medium fs-lg-3 font-lato-bold">What is F-biotic</div>
                    <p class="mt-3 txt-align">
                        Resistant starch is undeniably one of the most underrated, unknown and yet to be discovered functional foods in the world and F-Biotic from Arishina Life
                        Sciences
                        is
                        an innovative resistant starch produced from fruits like Banana <br><br>

                        We, at Arishina Life sciences , have been able to isolate resistant starch from the fruit using our own proprietary process technology and have developed
                        the
                        product F-Biotic with the highest amount of resistant starch.<br><br>

                        This sustainable product classified as dietary fiber is backed by high technology and research and has a broad range of application in functional food
                        sector.
                    </p>
                    <table class="mt-4 font-lato-medium table table-bordered text-center">
                        <tr class="">
                            <th class="w-50p">Types of Resistant Starch</th>
                            <th>Sources</th>
                        </tr>
                        <tr>
                            <td>RS1</td>
                            <td class="text-left">Physically inaccessible starch embedded in nondigestible kernel or cereal grains like unmilled seeds</td>
                        </tr>
                        <tr>
                            <td>RS2</td>
                            <td class="text-left">RS granules found naturally in n raw potato or high-amylose maize or in green banana, has a B- or C-type polymorph</td>
                        </tr>
                        <tr>
                            <td>RS3</td>
                            <td class="text-left">Retrograded starch made by alternative cooking-cooling such as potatoes cooled after cooking and puddings)
                            </td>
                        </tr>
                        <tr>
                            <td>RS4</td>
                            <td class="text-left">Chemically modified starch
                            </td>
                        </tr>
                    </table>
                </div>
            </template>
        </product-page-template>
        <div id="#MechanismOfAction">
            <product-page-template :image="mechanismOfActionImg" design="design-c">
                <template #info>
                    <div class="mt-lg-5">
                        <p class="mb-3 font-weight-medium fs-lg-3 font-lato-bold">Mechanism of Action of F-Biotic</p>

                        <p class="mt-3 txt-align">

                            F-Biotic which is Resistant Starch , after escaping the small intestine , reaches the (colon) large intestine , where it becomes an energy source for
                            the
                            bacteria
                            present in the colon.
                            <br><br>
                            These microbiota ferments the resistant starch further to produce Short Chain Fatty Acids (SCFA) which exerts a beneficial effect on the numerous
                            biomarkets
                            of
                            the
                            body .
                        </p>
                    </div>
                </template>
            </product-page-template>
        </div>
        <div id="#HealthBenefits">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <p class="mb-3 font-weight-medium fs-lg-3 font-lato-bold">Health benefits of F-Biotic</p>
                        <ol class="fs-1 txt-align ml-4 c-list">
                            <li class="mb-2"> Improved Digestive Health: Fbiotic acts as a prebiotic, feeding the beneficial bacteria in the gut. This can lead to a healthier gut
                                microbiome, improved bowel regularity, and reduced risk of colon cancer.
                            </li>
                            <li>
                                Enhanced Glycemic Control: Consuming Fbiotic can help stabilize blood sugar levels by slowing down the digestion of carbohydrates, which can be
                                particularly
                                beneficial for individuals with diabetes or insulin resistance.
                            </li>
                            <li>
                                Weight Management: Fbiotic can increase feelings of fullness and reduce appetite, potentially aiding in weight management. It also has fewer
                                calories
                                compared to regular starch.
                            </li>
                            <li>
                                Improved Insulin Sensitivity: Regular intake of Fbiotic may improve insulin sensitivity, which can help prevent metabolic syndrome and type 2
                                diabetes.
                            </li>
                            <li>
                                Reduced Inflammation: Some studies suggest that resistant starch can reduce inflammation in the gut, which may have beneficial effects on
                                inflammatory
                                conditions like Crohn's disease or ulcerative colitis.
                            </li>
                            <li>
                                Enhanced Mineral Absorption: Fbiotic may enhance the absorption of minerals such as calcium and magnesium, contributing to better bone health.
                            </li>
                            <li>
                                Better Heart Health: Incorporating Fbiotic into your diet may help lower cholesterol levels and reduce the risk of heart disease.
                            </li>
                            <li>
                                Mood Regulation: Fbiotic can influence the production of short-chain fatty acids in the gut, which may have a positive impact on mood and stress
                                levels.
                            </li>
                            <li>
                                Better Gut Health
                            </li>
                            <li>
                                Reduced Blood Sugar & Improved Glucose Management
                            </li>
                            <li>
                                Weight Loss
                            </li>
                            <li>
                                Better Lipid Profile
                            </li>
                            <li>
                                Improved Renal Functions
                            </li>
                        </ol>
                    </div>
                    <div class="col-lg-6">
                        <img src="../assets/f-biotic/health-benefits.png" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </div>
        <resistant-starch-section id="#resistantStarch"/>
        <applications-f-biotic id="#applicaionsFbiotic"></applications-f-biotic>
        <div class="container pb-5 mt-4">
            <iframe class="w-100p i-frame-video-lg" src="https://www.youtube.com/embed/HpVZTkQjaSI"
                    title="F Biotic ; An innovative dietary fiber rich in natural resistant starch ."
                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
import ProductPageTemplate from '@components/ProductPageTemplate';
import img from '../../../../assets/products/product-1.jpg';
// import img2 from '../../../../assets/products/STARCH.jpg';
import img2 from '../assets/f-biotic/fbiotic-product.jpg';
import mechanismOfActionImg from '../assets/f-biotic/mechanism-of-action.png';
import ResistantStarchSection from './ResistantStarchSection';
import ApplicationsFBiotic from './ApplicationsF-biotic';

export default {
    name: 'F-biotic',
    components: { ApplicationsFBiotic, ResistantStarchSection, ProductPageTemplate },
    data () {
        return {
            productImg: img,
            img2: img2,
            mechanismOfActionImg: mechanismOfActionImg,
            isPageReloaded: this.$store.getters.reloaded
        };
    },
    mounted () {
        if (localStorage.getItem('reloaded')) {
            // The page was just reloaded. Clear the value from local storage
            // so that it will reload the next time this page is visited.
            localStorage.removeItem('reloaded');
        } else {
            // Set a flag so that we know not to reload the page twice.
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
    }
};
</script>

<style scoped>

</style>
