<template>
    <div>
        <swiper class="swiper swiper-basic" data-mousewheel="1" data-parallax="1" :options="swiperOption">
            <swiper-slide>
                <base-banner class="d-sm-none" heading-align="center" heading="" height="full"
                             :img="bannerImg"
                             bg-position="top">
                </base-banner>
                <base-banner class="d-lg-none" heading-align="bottom" heading="" height="full"
                             :img="bannerMobileImg"
                             bg-position="top">
                </base-banner>

            </swiper-slide>
            <!--                SLIDE II-->
            <swiper-slide>
                <base-banner class="d-sm-none" heading-align="center" heading="" height="full"
                             :img="bannerImg2"
                             bg-position="bottom">
                    <template #default>
                        <h1 class="text-white font-lato-semibold c-text-3x bg-dark-trans px-4 py-2" v-if="heading!==''">
                            Welcome to India’s first company to be certified by Rain Forest Alliance</h1>
                    </template>
                </base-banner>
                <base-banner class="d-lg-none" heading-align="bottom" heading="" height="full"
                             :img="bannerMobileImg2"
                             bg-position="top">
                    <template #default>
                        <h1 class="text-white font-lato-semibold c-text-3x bg-dark-trans px-4 py-2" v-if="heading!==''">
                            Welcome to India’s first company to be certified by Rain Forest Alliance</h1>
                    </template>
                </base-banner>

            </swiper-slide>
            <!--            SLIDE III-->
            <swiper-slide>
                <base-banner class="d-sm-none" heading-align="center" heading="" height="full"
                             :img="bannerImg3"
                             bg-position="bottom">
                    <template #default>
                        <h1 class="text-white font-lato-semibold c-text-3x bg-dark-trans px-4 py-2" v-if="heading!==''">
                            LUTEPURE <br>

                            Nature’s Richest Source of Lutein is here</h1>
                    </template>
                </base-banner>
                <base-banner class="d-lg-none" heading-align="bottom" heading="" height="full"
                             :img="bannerMobileImg3"
                             bg-position="top">
                    <template #default>
                        <h1 class="text-white font-lato-semibold c-text-3x bg-dark-trans px-4 py-2" v-if="heading!==''">
                            LUTEPURE <br>

                            Nature’s Richest Source of Lutein is here</h1>
                    </template>
                </base-banner>

            </swiper-slide>
            <!--            banner IV-->
            <swiper-slide>
                <base-banner class="d-sm-none" heading-align="center" heading="" height="full"
                             :img="bannerImg4"
                             bg-position="bottom">
                    <template #default>
                        <h1 class="text-white font-lato-semibold c-text-3x bg-dark-trans px-4 py-2" v-if="heading!==''">
                            DELIVERING RESPONSIBLE CURCUMIN TO THE WORLD</h1>
                    </template>
                </base-banner>
                <base-banner class="d-lg-none" heading-align="bottom" heading="" height="full"
                             :img="bannerMobileImg4"
                             bg-position="top">
                    <template #default>
                        <h1 class="text-white font-lato-semibold c-text-3x bg-dark-trans px-4 py-2" v-if="heading!==''">
                            DELIVERING RESPONSIBLE CURCUMIN TO THE WORLD</h1>
                    </template>
                </base-banner>

            </swiper-slide>

            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        </swiper>
    </div>
</template>
<script> import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import BaseBanner from '@components/BaseBanner';
import bannerImg from '../../assets/home/HOME.jpg';
import bannerImg2 from '../../assets/home/HOME-2.jpg';
import bannerImg3 from '../../assets/home/HOME-3.jpg';
import bannerImg4 from '../../assets/home/HOME-4.jpg';
import bannerMobileImg from '../../assets/home/banner-mobile-2.jpg';
import bannerMobileImg2 from '../../assets/home/600x600-HOME-2.jpg';
import bannerMobileImg3 from '../../assets/home/600x600-HOME-3.jpg';
import bannerMobileImg4 from '../../assets/home/600x600-HOME-4.jpg';

export default {
    name: 'HomeBannerSlider',
    components: { BaseBanner, Swiper, SwiperSlide },
    data () {
        return {
            bannerImg: bannerImg,
            bannerMobileImg: bannerMobileImg,
            bannerImg2: bannerImg2,
            bannerMobileImg2: bannerMobileImg2,
            bannerImg3: bannerImg3,
            bannerMobileImg3: bannerMobileImg3,
            bannerImg4: bannerImg4,
            bannerMobileImg4: bannerMobileImg4,
            bannerItems: [
                {
                    title: '',
                    img: bannerImg,
                    imgMobile: bannerMobileImg,
                    bgPosition: 'top'
                },
                {
                    title: 'Welcome to India’s first company to be certified by Rain Forest Alliance',
                    img: bannerImg2,
                    imgMobile: bannerMobileImg2,
                    bgPosition: 'bottom'
                },
                {
                    title: '',
                    img: bannerImg3,
                    imgMobile: bannerMobileImg3,
                    bgPosition: 'top'
                }
            ],
            swiperOption: {
                autoHeight: true,
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: { el: '.swiper-pagination', clickable: true },
                mousewheel: false,
                parallax: false,
                centeredSlides: true,
                // autoplay: true,
                autoplay: { delay: 6000, disableOnInteraction: false },
                navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
                breakpoints: {
                    1200: { speed: 1500 },
                    1024: { slidesPerView: 1, spaceBetween: 0, speed: 1000 },
                    768: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    320: { slidesPerView: 1, spaceBetween: 0, speed: 800 }
                }
            }
        };
    }
}; </script>
}
}
<style scoped lang="scss"> .item {
    width: 100%;
    display: flex;
    align-items: center;
}

/*.bg-home-banner {*/
/*    background-image: url('../../assets/img/social-banner/home-banners-mob.jpg');*/
/*}*/

/*.bg-home-banner2 {*/
/*    background-image: url('../../assets/img/social-banner/home-banners-mob2.jpg');*/
/*}*/

/*.bg-home-banner3 {*/
/*    background-image: url('../../assets/img/social-banner/home-banners-mob3.jpg');*/
/*}*/

.home-banner-icon {
    display: inline-block;
}

.home-banner-icon img {
    width: 1.2rem;
    margin-left: 20px;
}

.home-slider h1 {
    line-height: 40px;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    //background-color: rgba(0, 0, 0, 0.4);
    //width: 4rem;
    bottom: 36px;

    .swiper-pagination-bullet {
        border: 1px solid var(--color-white) !important;

    }

}

.swiper-pagination-bullet {
    border: 1px solid var(--color-white) !important;

}

/*.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {*/
/*    bottom: 10px;*/
/*    left: 50%;*/
/*    transform: translate(-50%, 0);*/
/*    width: unset;*/
/*    padding: 3px 25px;*/
/*    background: #2929293b;*/
/*    border-radius: 15px;*/
/*}*/

/*@media (min-width: 992px) {*/
/*    .bg-home-banner {*/
/*        background-image: url('../../assets/img/social-banner/home-banners.jpg');*/
/*    }*/

/*    .bg-home-banner2 {*/
/*        background-image: url('../../assets/img/social-banner/home-banners2.jpg');*/
/*    }*/

/*    .bg-home-banner3 {*/
/*        background-image: url('../../assets/img/social-banner/home-banners3.jpg');*/
/*    }*/

/*    .home-banner-icon img {*/
/*        width: 2rem;*/
/*        margin-left: 20px;*/
/*    }*/
/*}*/
</style>
