<template>
    <main>
        <div class="container py-7">
            <!--            <h4 class="text-uppercase text-danger lt-gp-2 mb-4 text-center" data-aos="fade-up" data-aos-duration="1500">CONTACT</h4>-->
            <h4 class="text-uppercase lt-gp-2 mb-4 text-center text-primary font-weight-bold fs-6">CONTACT US</h4>
            <!--            <div class="row mb-5" data-aos="fade-up" data-aos-duration="1500">-->

            <div class="row mb-5">

                <div class="col-12 contact-form">

                    <transition name="fade" mode="out-in">

                        <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-if="state === 'S'" key="'S">
                            <div class="text-success">
                                <div class="text-center mb-4">
                                    <i class="fa fa-check-square-o  fa-3x" aria-hidden="true"></i>
                                </div>
                                <div class="text-center">
                                    <p class="fs-4">Thank you <strong>{{ firstName }}</strong> for Contacting us
                                    </p>
                                    We have received your request and will respond to you within 24 hours. For
                                    urgent enquiries please call us on the telephone numbers provided.
                                </div>
                            </div>
                        </div>

                        <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-else-if="state === 'F'"
                             key="'F">
                            <div class="text-danger">
                                <div class="text-center mb-4">
                                    <i class="fa fa-window-close  fa-3x" aria-hidden="true"></i>
                                </div>
                                <div class="text-center">
                                    <p class="fs-4"><strong>OOPS!</strong></p>
                                    Something has gone wrong, Please try again.
                                </div>
                            </div>
                        </div>

                        <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-else-if="state === 'L'"
                             key="'L">
                            <div class="text-center p-4">
                                <loading-animation/>
                                <p class="c-font-19 mb-4 proxim-light">Please wait while communicate with
                                    the
                                    server</p>
                            </div>
                        </div>

                        <s-form class="contact-form row px-lg-6" v-else role="form" @submit="onSubmit"
                                autocomplete="off">

                            <div class="col-12">
                                <p class="text-primary fs-2" style="font-style: italic;">Fields marked with an asterisk
                                    (*) are required.</p>
                            </div>

                            <div class="col-12 col-md-6">
                                <validated-input name="First Name" v-model="firstName" shadow="0" label="First Name *"
                                                 :rules="rules.first_name" required/>
                            </div>

                            <div class="col-12 col-md-6">
                                <validated-input name="Last Name" v-model="lastName" shadow="0" label="Last Name *"
                                                 :rules="rules.last_name" required/>
                            </div>

                            <div class="col-12 col-md-6">
                                <validated-input type="email" name="Email" v-model="email" shadow="0"
                                                 label="Email *"
                                                 :rules="rules.email_id"/>
                            </div>

                            <div class="col-12 col-md-6">

                                <validated-input name="Subject" v-model="subject" shadow="0"
                                                 label="Subject *"
                                                 :rules="rules.subject"/>
                            </div>

                            <!--                            <div class="col-12 col-md-6">-->
                            <!--                                <validated-input name="Purpose" v-model="purpose" shadow="0"-->
                            <!--                                                 label="Purpose *"-->
                            <!--                                                 :rules="rules.purpose"/>-->
                            <!--                            </div>-->

                            <div class="col-12">
                                <validated-text-area label="Message" v-model="message"
                                                     :rules="rules.message1"
                                                     shadow="0"/>
                            </div>

                            <div class="col-12 text-center">
                                <!--                                <button class="lego-btn  bg-primary round-1 px-5 py-4 text-bold" type="submit">-->
                                <!--                                    Submit-->
                                <!--                                </button>-->
                                <btn type="submit" text="Submit" border-radius="0" class="px-5 mt-3"></btn>
                            </div>
                        </s-form>
                    </transition>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import axios from 'axios';
// import axios from 'secure-axios';
export default {
    name: 'ContactUs',
    props: {},
    components: {},
    data () {
        return {
            state: 'D',
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            purpose: '',
            message: '',
            rules: {
                first_name: {
                    required: true
                },
                last_name: {
                    required: true
                },
                subject: {
                    required: true
                },
                purpose: {
                    required: true
                },
                email_id: {
                    required: true
                },
                message1: {
                    required: false
                }
            }
        };
    },
    mounted () {
        const bodyFormData = new FormData();
        axios({
            method: 'post',
            url: 'https://arishina.in/mail.php',
            // url: 'https://arishina.xeoscript.com/mail.php',
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        // axios.form('https://arishina.in/mail.php', {
        // });
    },
    methods: {
        onSubmit () {
        //     axios.form('https://arishina.in/mail.php', {
        //         first_name: this.firstName,
        //         last_name: this.lastName,
        //         email: this.email,
        //         subject: this.subject,
        //         message: this.message
        //     }).then(function (response) {
        //         console.log('response11', response);
        //     });
            // const bodyFormData = new FormData();
            // bodyFormData.append('first_name', this.firstName);
            // bodyFormData.append(' last_name', this.lastName);
            // bodyFormData.append('email', this.email);
            // bodyFormData.append('subject', this.subject);
            // // bodyFormData.append('purpose', this.purpose);
            // bodyFormData.append('message', this.message);
            //
            // const that = this;
            // this.state = 'L';
            //
            // axios({
            //     method: 'post',
            //     url: 'https://arishina.in/mail.php',
            //     // url: 'https://arishina.xeoscript.com/mail.php',
            //     data: bodyFormData,
            //     headers: { 'Content-Type': 'multipart/form-data' }
            // }).then(function (response) {
            //     that.onSubmitSuccess(response);
            // }).catch(function (response) {
            //     that.onSubmitFailed(response);
            // });
        },

        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        }
    }
};
</script>

<style lang="scss" scoped>

textarea {
    &.control {
        min-height: 8rem;
    }
}
</style>
