<template>
    <div class="banner-home pos-r" style="" :class="{'full-h':height === 'full','half-h':height === 'half'}">
        <div class="dark-overlay"></div>
        <video autoplay muted loop class="banner-video" ref="bannerVideo">
            <source src="../assets/video/banner-video-2.mp4">
            <!--        <video id="9a67dd82-c36b-9bb1-1701-6bb8e5e0b65f-video" autoplay="" loop="" style="background-image:url(&quot;https://uploads-ssl.webflow.com/5f85d0c81b6f5da2719b6b03/5fa0311e594b1f0fad2e1488_iStock-484013593-720-poster-00001.jpg&quot;)" muted="" playsinline="" data-wf-ignore="true" data-object-fit="cover" aria-hidden="true"><source src="https://uploads-ssl.webflow.com/5f85d0c81b6f5da2719b6b03/5fa0311e594b1f0fad2e1488_iStock-484013593-720-transcode.mp4" data-wf-ignore="true" aria-hidden="true"><source src="https://uploads-ssl.webflow.com/5f85d0c81b6f5da2719b6b03/5fa0311e594b1f0fad2e1488_iStock-484013593-720-transcode.webm" data-wf-ignore="true" aria-hidden="true"></video>-->
        </video>
        <div class="container">
            <div class="content">
                <section class="banner-section left text-center-res">
                    <h1 class="mb-4 text-white text-3x">{{ heading }}</h1>
                    <div v-if="$router.currentRoute.name ==='Home'">
                        <div class="logo mb-3">
                            <img src="../assets/img/logo/footer-logo.png" class="img-fluid" style="height: 8.5rem;" alt="">
                        </div>
                        <h2 class="text-2x font-weight-bold text-white">SUPPORTED
                            BY SCIENCE.
                            REINFORCED BY RESEARCH.</h2>
                    </div>
                    <!--                <btn text="Our Bulls" size="lg" class="px-5 py-4 poppins-500" border-radius="2"></btn>-->
                </section>
            </div>
            <!--      <div class="content container">-->
            <!--        <section class="banner-section text-center">-->
            <!--          <h1 class="text-primary mb-3 oswald-600 text-4x">MILES AHEAD IN THE GENE RACE</h1>-->
            <!--          <p class="mb-5 text-white poppins-500 lt-s-1">Glorious Five Decades of Existence</P>-->
            <!--          <btn text="Our Bulls" size="lg" class="px-5 py-4 poppins-500" border-radius="2"></btn>-->
            <!--        </section>-->
            <!--      </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseVideoBanner',
    props: {
        height: {
            type: String,
            default: 'half'
        },
        heading: {
            type: String,
            default: ''
        }
    },
    mounted () {
        this.$refs.bannerVideo.play();
        // console.log('banner-video', this.$refs.bannerVideo);
        // document.getElementById('vid').play();
    }
};
</script>

<style lang="scss">
.full-h {
    min-height: 100vh;
}

.half-h {
    min-height: 50vh;
}

.banner-home {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    //min-height: 100vh;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: 992px) {
        justify-content: flex-end;
    }
    z-index: 2;

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.8;
    }

    .content {
        position: relative;
        z-index: 10;
        display: flex;
        justify-content: flex-start;

        .banner-section {
            width: 100%;
            @media (min-width: 992px) {
                width: 45%;
            }
        }
    }

    .dark-overlay {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 1);
        //z-index: 2;
        // background: var(--color-primary);
        // mix-blend-mode: overlay;
    }
}

// .banner-video {
//   position: relative;
//   right: 0;
//   bottom: 0;
//   min-width: 100%;
//   // min-height: 100%;
// }
// .content {
//   position: fixed;
//   bottom: 0;
//   // background: rgba(0, 0, 0, 0.5);
//   color: #f1f1f1;
//   width: 100%;
//   /* width: 35%; */
//   height: 100%;
//   padding: 20px;
//   display: flex;
//   // flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   @media (min-width:992px) {
//       justify-content: flex-end;
//   }

//   .banner-section{
//     width:100%;
//     @media (min-width:992px) {
//       width:40%;
//     }
//   }
// }

</style>
