<template>
    <div class="about">
        <section>
            <base-banner heading="Manufacturing" :img="topBanner" class="" height="half"></base-banner>
        </section>
        <div class="container py-5">
            <div class="row">
                <section class="col-12 col-lg-6 order-2 order-lg-1">
                    <img src="../assets/manufacturing/CERTIFICATION.jpg" class="img-fluid">
                </section>
                <section class="col-12 col-lg-6 order-1 order-lg-2" style="">
                    <base-short-info heading="Manufacturing Capability">
                        <template #content>
                            <p class="fs-1 font-weight-400 txt-align">
                                Arishina’s manufacturing facility is situated in an area surrounded by the Western
                                Ghats in Gundlupet, India. The factory offers processing capabilities that match
                                international standards and stringent quality parameters. The facility has already
                                been recognized with certifications Including BRCGS (AA) Grade, FSMA, GMP, USFDA,
                                KOSHER,
                                and HALAL, NPOP and NOP. The company thrives on the highest level of scientific and technical
                                excellence
                                that provides much-needed value to its products, clients, and customers. Quality
                                assurance
                                reigns supreme at Arishina, the reason why its labs are equipped with industry-leading
                                machineries such as Gas Chromatography and Liquid Chromatography
                            </p>
                        </template>
                    </base-short-info>
                </section>

            </div>
        </div>
        <!--        <section class="">-->
        <!--            <base-short-info heading="Our Commitment to Science.">-->
        <!--                <template #content>-->
        <!--                    <p class="text-primary fs-1 font-weight-400">-->
        <!--                        Our herbal ingredients have been clinically studied and shown effective in both animal and human-->
        <!--                        clinical trials. Many of our clinical trials are peer-reviewed, double-blind controlled trials-->
        <!--                        with impressive results. In addition to formal research tests, our ingredients go through-->
        <!--                        rigorous scientific analysis with our state-of-the-art testing equipment under GLP-certified-->
        <!--                        conditions.-->
        <!--                    </p>-->
        <!--                </template>-->
        <!--            </base-short-info>-->
        <!--        </section>-->
        <!--        <section class="">-->
        <!--            <parallax-banner :bg-image-url="banner2"></parallax-banner>-->
        <!--        </section>-->
        <!--        <section class="">-->
        <!--            <base-short-info heading="Our Ayurvedic Roots.">-->
        <!--                <template #content>-->
        <!--                    <p class="text-primary fs-1 font-weight-light">-->
        <!--                        For 6,000 years, the natural system known as Ayurveda has supported the health needs of-->
        <!--                        individuals through natural botanicals. Ayurveda is a science of life (Ayur = life, Veda =-->
        <!--                        science) that offers a systematic approach to wellness that helps human beings realize their-->
        <!--                        full potential. We respect the wisdom inherent in Ayurveda and work closely with the best-->
        <!--                        scientists of Ayurvedic institutes to make this wisdom available to a global market.-->
        <!--                    </p>-->
        <!--                </template>-->
        <!--            </base-short-info>-->
        <!--        </section>-->
        <!--        <section class="">-->
        <!--            <parallax-banner :bg-image-url="banner2"></parallax-banner>-->
        <!--        </section>-->
        <!--        <section class="">-->
        <!--            <base-short-info heading="Quality Begins on the Farm.">-->
        <!--                <template #content>-->
        <!--                    <p class="text-primary fs-1 font-weight-light">-->
        <!--                        Gencor botanicals are grown under the close supervision of our technical team and according to-->
        <!--                        strict quality-assurance processes. For instance, our herbs are always dried in the shade, so-->
        <!--                        they maintain their phytochemical content. All our extracts comply with the California Prop-65-->
        <!--                        law for heavy metals and our single-herb standardized extracts are free from all known sources-->
        <!--                        of contamination (including other herbs).-->
        <!--                    </p>-->
        <!--                </template>-->
        <!--            </base-short-info>-->
        <!--        </section>-->
    </div>
</template>
<script>

// import ParallaxBanner from '@components/ParallaxBanner';
import banner1 from '../assets/about/banner-1.jpg';
import topBanner from '../assets/manufacturing/MANUFACTURING.jpg';
import banner2 from '../assets/about/banner-2.jpg';
// import BaseVideoBanner from '@components/BaseVideoBanner';
import BaseShortInfo from '@components/BaseShortInfo';
import banner3 from '../assets/about/MANUFACTURING-2.jpg';
import BaseBanner from '@components/BaseBanner';

export default {
    name: 'ManufacturersPage',
    components: { BaseBanner, BaseShortInfo },
    data () {
        return {
            banner1: banner1,
            banner2: banner2,
            banner3: banner3,
            topBanner: topBanner
        };
    }
};
</script>

<style scoped lang="scss">
.parallax-banner {
    background: url("../assets/about/banner-1.jpg");

    min-height: 100vh;

    //Create a parallax scrolling effect
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
