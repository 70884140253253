<template>
    <div>
        <product-page-template title="" :image="img2" price="LUTEIN EXTRACTS">
            <template #info>
                <p class="mt-3 txt-align">

                    Arishina Life Sciences Pvt. Ltd. with its state-of-the-art technology, in compliance with global
                    standards, offers a wide range of Marigold Extracts – Lutein, that has found a considerable space in the
                    global market. Gundlupet known as the flower pot of Karnataka is one of the major growing regions for
                    flowers like Marigold & Sunflower. Marigold flower petals are considered to be the richest source of
                    Lutein which has immense applications in the food and health sector.</p>
                <p class="mb-2 font-weight-medium-3 font-weight-medium">LUTEIN FOR HEALTH</p>
                <ol class="pl-5 mb-3">
                    <li class="">Improves eyesight, and relieves eye strain.</li>
                    <li class=""> Reduces the incidence of eye diseases such as cataract, age-related macular
                        degeneration, and glaucoma.
                    </li>
                    <li class="">Prevents chronic inflammation and oxidative stress.</li>
                    <li class="">Enhances skin tone.</li>
                </ol>
                <p class="txt-align">Marigold is solvent extracted to obtain Lutein, its active ingredient, which is
                    present only in the amounts of 0.1- 0.15% per flower. Large quantities of flowers are plucked manually
                    involving an extensive amount of labor and are further processed to extract the pigment. The pigments
                    are heat-sensitive and therefore very high temperatures cannot be used for drying, and to dry such a
                    voluminous quantity of fresh flowers a preliminary process known as silage is employed. During this
                    step, flowers are allowed to rot to the point where the cells break down and water oozes out. Silage
                    operation requires huge spaces & appropriate technology to handle the post effluents. Direct access to
                    the marigold farm fields in Gundlupet ensures maintaining of stringent quality control
                    from the raw material stage itself. Apart from this our technical
                    know-how in Marigold processing also puts us ahead of others enabling us to
                    extract the highest quality of Lutein concentrates for the Nutraceutical industry.</p>
            </template>
        </product-page-template>
        <div class="container py-lg-5">
            <div class="row">
                <div class="col-12">
                    <p class="mb-3 font-weight-medium-3   font-weight-medium">Marigold Extract Free
                        Lutein</p>
                    <ul class="pl-5">
                        <li class=""> Free Lutein 5% to 80% Powder</li>
                        <li class=""> Free Lutein 5% to 40% Oil Suspension</li>
                        <li class=""> Free Lutein 5% to 30% Granules</li>
                        <li class="">Free Lutein 5% to 30% Water soluble / Water Dispersible</li>
                    </ul>
                    <p class="mt-3   font-weight-medium-3   font-weight-medium">Marigold Extract Lutein
                        Esters</p>
                    <ul class="pl-5">
                        <li class="">Lutein Ester 5% to 75% Powder</li>
                        <li class="">Lutein Ester 5% to 40% Oil Suspension</li>
                        <li class="">Lutein 5% to 30% Granules</li>
                        <li class="">Lutein Ester 5% to 20% Water soluble / Water Dispersible</li>
                        <li class="">Lutein Ester 5% to 30% odour free</li>
                    </ul>
                </div>
                <div class="col-12">
                    <div class="fl-x-cc py-5">
                        <iframe class="w-100p i-frame-video-lg mt-4" src="https://www.youtube.com/embed/VfwdwZeH2yA"
                                title="LUTEPURE; Arishina Life Science&#39;s sustainably produced Lutein Extracts"
                                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductPageTemplate from '@components/ProductPageTemplate';
import img from '../../../assets/products/product-1.jpg';
import img2 from '../../../assets/products/LUTEIN.jpg';

export default {
    name: 'LuteinExtract',
    components: { ProductPageTemplate },
    data () {
        return {
            productImg: img,
            img2: img2
        };
    }
};
</script>

<style scoped>

</style>
