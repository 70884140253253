<template>
    <div class="py-2">
        <div class="fs-lg-2 font-lato-bold text-primary mb-2">{{title}}</div>
        <div class="bs-5 p-4 round-2">
            <div class="singleTeam2 wow brochure-card-min-h fl-y fl-j-c h-100p" data-wow-duration="700ms" data-wow-delay="300ms"
                 style="visibility: visible; animation-duration: 700ms; animation-delay: 300ms;">
                <!-- <img :src="require('@/assets/images/' + img)" class="img-fluid" alt="#">--> <img :src="img" class="img-fluid h-100p" alt="#">
                <div class="teamHoverContent"></div>
                <div class="teamDetails bg-primary w-15r py-2 mb-2"><a :href="linkUrl" target="_blank" download class="text-decoration-none w-100p fs-lg-1">Download Brochure</a></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    name: 'BrochureCard',
    props: {
        img: { type: String, default: '' },
        title: { type: String, default: '' },
        linkUrl: { type: String, default: '' }
    }
};
</script>
<style lang="scss" scoped> .card-img {
    width: 16.875rem;
    height: 17.812rem;
}
.brochure-card-min-h{
    min-height: 15rem;
}

.card-img:hover {
    background: rgba(184, 152, 65, 0.7);
}

.card-footer {
    top: 0;
    width: 13.812rem;
    height: 6.5rem;
    background: #2c3da7;
}

.singleTeam2 {
    position: relative;

    .teamHoverContent {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        /*background: rgba(184, 152, 65, 0.7);*/
        /*background: rgba(128, 128, 128, 0.05);*/
        opacity: 0;
        visibility: hidden;
        transition: all ease-in-out 600ms;
        -moz-transition: all ease 400ms;
        -webkit-transition: all ease 400ms;
    }

    .teamDetails {
        /*width: 222px;*/
        /*height: 101px;*/
        /*background: #2c3da7;*/
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        text-align: center;
        line-height: 0.8;
        /*padding: 32px 0;*/
        /*opacity: 0;*/
        /*visibility: hidden;*/
        transition: all ease 700ms;
        -moz-transition: all ease 700ms;
        -webkit-transition: all ease 700ms;
        -ms-transition: all ease 700ms;
        -o-transition: all ease 700ms;

        a {
            color: #FFF;
            display: inline-block;
            /*text-transform: uppercase;*/
            /*font-weight: 700;*/
            /*font-size: 16px;*/
            letter-spacing: 1px;
            /*line-height: 0.8;*/
            /*margin-bottom: 15px;*/
        }

        span {
            display: block;
            font-size: 12px;
            line-height: 0.8;
            text-transform: uppercase;
            font-weight: 400;
            color: #FFF;
            letter-spacing: 6px;
        }
    }

    &:hover {
        .teamHoverContent {
            opacity: 1;
            visibility: visible;
        }

        .teamDetails {
            opacity: 1;
            visibility: visible;
            /*bottom: -13px;*/
        }
    }
}
</style>
