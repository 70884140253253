<template>
    <product-page-template design="design-b">
        <template #info>
            <p class="mb-3 font-weight-medium fs-lg-4 font-lato-bold">Resistant Starch; a key ally to your health
            </p>
            <ol class="fs-1 txt-align ml-4 pr-4 c-list">
                <li>
                    <b>Prebiotic Effect:</b> Resistant starch serves as a food source for beneficial gut bacteria, particularly Bifidobacteria and Lactobacilli, promoting their
                    growth
                    and activity.
                </li>
                <li>
                    <b>Production of Short-Chain Fatty Acids:</b> The fermentation of resistant starch in the colon leads to the production of short-chain fatty acids (SCFAs)
                    like
                    butyrate, propionate, and acetate. These SCFAs provide energy for colonocytes, maintain the integrity of the gut barrier, and have anti-inflammatory
                    effects.
                </li>
                <li>
                    <b>Diversity of Microbiota:</b> A diet rich in resistant starch can increase the diversity of the gut microbiome, which is associated with better gut health
                    and
                    overall well-being.
                </li>
                <li>
                    <b>Gut-Brain Axis:</b> SCFAs from resistant starch can influence brain health by modulating neurotransmitter production, reducing inflammation, and
                    impacting the
                    stress response.
                </li>
                <li>
                    <b>Gut-Skin Axis:</b>A healthy gut microbiome supported by resistant starch can reduce systemic inflammation and oxidative stress, potentially improving skin
                    health and reducing the incidence of conditions like acne and eczema
                </li>
            </ol>
        </template>
    </product-page-template>
</template>

<script>
import ProductPageTemplate from '../../../../components/ProductPageTemplate';
import img from '../assets/img/f-biotic/placeholder-600-400.svg';

export default {
    name: 'ResistantStarchSection',
    components: {
        ProductPageTemplate
    },
    data () {
        return {
            img: img
        };
    }
};
</script>

<style scoped>

</style>
