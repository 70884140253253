<template>
    <product-page-template :image-border="true" title="" :image="img2" price="Natural Colours">
        <template #info>
            <p class="mt-3 txt-align">
                Arishina has developed natural alternatives for synthetic colors. Natural yellow to orange color which
                is best suited for the food, beverage, and pharmaceutical industries. This helps enrich the antioxidant
                properties of food and beverages. These colors are customized according to the requirement of the
                industry.</p>

            <ul class="pl-5">
                <li>All yellow shades from Turmeric Application.</li>
                <li> Confectionery.</li>
                <li>Ice Creams.</li>
                <li> Sweets.</li>
                <li> Food & Beverages.</li>
                <li> Ready to Drink.</li>

            </ul>

        </template>
    </product-page-template>
</template>

<script>
import ProductPageTemplate from '@components/ProductPageTemplate';
import img from '../../../assets/products/product-1.jpg';
import img2 from '../../../assets/products/Naturalcolour.jpg';

export default {
    name: 'Naturalcolors',
    components: { ProductPageTemplate },
    data () {
        return {
            productImg: img,
            img2: img2
        };
    }
};
</script>

<style scoped>

</style>
