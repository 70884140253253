<template>
    <div>
        <div class="container pt-4 pt-lg-5 pb-4">
            <div class="row">
                <div class="float-left col-lg-4 col-md-6">
                    <h1 class="text-uppercase text-primary font-lato-bold text-center mb-3 fs-6 fs-lg-7">
                        Curcumin Extract
                    </h1>
                    <img :src="productImg" alt="" class="img-fluid round-3">
                </div>
                <div class="col-lg-8 col-md-6">
                    <div class="mt-lg-6 mt-3">
                        <h2 class="fs-lg-2 text-uppercase font-lato-bold fs-2"><span>DELIVERING RESPONSIBLE CURCUMIN TO THE WORLD</span></h2>

                        <p class="mt-3 txt-align">
                            Curcumin is the primary active constituent in turmeric derived from the rhizomes of Curcuma Longa.
                            Almost all the benefits of turmeric are credited to curcumin. In spite of its many favorable
                            properties, curcumin is practically insoluble in water and relatively unstable, thus limiting its
                            application. Curcumin is poorly absorbed and rapidly metabolized and eliminated when eaten, so
                            little reach our circulation.</p>

                        <p class="mb-2 mt-4 text-uppercase font-lato-bold fs-lg-2 font-weight-medium-3 txt-align font-weight-medium"> Curcumin:
                            Offering a world of health benefits</p>

                        <p class="txt-align"> Curcumin has anti-inflammatory properties, making it a choice for effective treatment for
                            inflammatory conditions such as arthritis. Curcumin effectively increases the level of the protein
                            that helps in mitigating the symptoms of depression. By improving insulin resistance, curcumin may
                            help prevent type 2 diabetes. Past research notes that curcumin may prevent tumors from forming and
                            becoming cancerous.
                            Turmeric is an anti-inflammatory, anti-microbial and anti-oxidant-rich spice, making it potentially
                            effective for treating skin conditions like acne and also it helps in losing weight. Curcumin’s
                            potential as a therapeutic agent may not necessarily rely on its bio-availability, but rather, its
                            medicinal benefits may also arise from its positive influence on gastrointestinal health and
                            function. It is well recognized that gut health is not only essential for our gastrointestinal
                            system but is also
                            important for overall human health.</p>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="container pb-lg-5 pb-4">
                <div class="row">
                    <div class="col-lg-12">
                        <p class="mb-2 mt-lg-4 font-weight-medium-3 font-lato-bold txt-align  font-weight-medium">
                            APPLICATIONS</p>

                        <p class="txt-align"> Anti-inflammatory | Neuroprotective | Anti-diabetic
                            Anti-cancer | Cardioprotective
                            Anti-oxidant | Radioprotection</p>
                        <p class="txt-align"> Arishina with state-of-the-art technology in compliance with global standards offers a range of
                            curcumin extracts suitable for a broad range of applications. With over a decade of experience, we
                            are the pioneers in manufacturing superior curcumin through cutting-edge technology and ongoing
                            research. At Arishna, we understand nature and natural products better and believe in delivering
                            responsible turmeric to the world. The company adheres to ‘Rain Forest Alliance’ a collective
                            movement currently appreciated and promoted globally. It ensures the symbiotic co-existence of
                            farmers, companies, foresters, and consumers with a vision to produce harmonious and sustainable
                            goods.</p>

                        <p class="txt-align"> The supply chain management at Arishina is currently certified by the rainforest alliance. Arishina
                            in collaboration with the farmers is also focused on implementing the best ways to improve and
                            manage any kind of cultivation by improving the quality of the soil. Right from its inception, the
                            company follows ‘Regenerative Agricultural Practices (RAP), a global approach to improving soil
                            fertility by increasing its organic composition.

                        </p>
                    </div>
                    <!--                    <div class="col-lg-5">-->
                    <!--                        <iframe class="w-100p i-frame-video mt-lg-6" src="https://www.youtube.com/embed/WTof9rZ4zgg"-->
                    <!--                                title="Sustainable Production of Curcumin by Arishina Life Sciences"-->
                    <!--                                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"-->
                    <!--                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>
        <div>
            <div class="container py-lg-5 pb-5">
                <div class="fl-x-cc">
                    <iframe class="w-100p i-frame-video-lg" src="https://www.youtube.com/embed/WTof9rZ4zgg" title="Sustainable Production of Curcumin by Arishina Life Sciences"
                            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import ProductPageTemplate from '@components/ProductPageTemplate';
// import img from '../../../assets/products/product-1.jpg';
import img2 from '../../../assets/products/curcumin.jpg';
import img3 from '../../../assets/products/curdis.jpg';
import img4 from '../../../assets/products/curostain.jpg';

export default {
    name: 'CurcuminExtract',
    components: {},
    data () {
        return {
            productImg: img2,
            img3: img3,
            img4: img4

        };
    }
};
</script>

<style scoped>
    .float-left {
        float: left;
    }

    .float-right {
        float: right;
    }
</style>
