<template>
    <!--    <section v-if="dataLoading" class="about py-lg-9">-->
    <!--        <div class="text-center pt-7 pb-7">-->
    <!--            <loading-animation class="text-primary text-5x"/>-->
    <!--        </div>-->
    <!--    </section>-->
    <div>
        <div class="container pt-5">
            <!--            <base-breadcrumb :path="['News', details.news.title]"-->
            <!--                             class="    "></base-breadcrumb>-->
            <div class="ml-lg-5 mr-lg-8">
                <div class="row">
                    <div class="col-12">

                        <div class="ml-3 mt-3">
                            <h6 class="font-weight-bold font-semi-bold fs-lg-9">
                                Mitigating contamination of spices with rainforest alliance certification
                            </h6>
                            <div class="mb-2">
                                <span class="text-uppercase fs--2 mr-2">
                                    November 29, 2022 - Updated 12:42 pm IST
                                    </span>
                            </div>
                            <div class="mb-2">
                                <span class="text-secondary font-inter-bold text-uppercase fs--1 mr-2">
                                    BY BL KOCHI BUREAU
                                    </span>
                            </div>
                            <img class="img-fluid"
                                src="../../../assets/img/news/arishina-earns-rainforest-alliance-certification-against.jpeg"
                                alt="">
                            <p class=" mt-3 font-inter-medium align-justify">
                                Kochi: High pesticide residues in spices especially turmeric and chilly have been a
                                grave concern and impediment to the dietary and food supplement industry. Contamination
                                of spices occurs mainly through pollution from heavy metals, industrial emissions,
                                effluents, and also through anthropogenic activities.
                            </p>
                            <p class="mt-3">
                                Consumption of natural ingredients laced with hazardous residues found in food,
                                nutrition, and dietary supplements, in the long run, can pose serious health ailments.
                                Pesticide contamination not only impacts direct-to-consumer business but also directly
                                correlates to the import of agricultural products. Foreign regulatory authorities off
                                late have initiated stringent measures in setting up tolerance level of pesticide
                                residues that is permitted on human or animal food products.
                            </p>

                            <p class="mt-3">
                                Kerala-based Arishina Life Sciences, manufacturers of spices and botanical extracts have
                                resorted to certifying its supply chain with the ‘Rainforest Alliance’ to promote
                                sustainable agricultural practices and fight pesticide contamination of herbs and
                                spices. The company has been successful in bringing down the level of pesticide
                                contaminants consistently to minimal values across their batches of turmeric and chilly
                                for over a year.
                            </p>

                            <div class="mt-3">
                                Rain Forest Alliance is an international non-governmental organization with a presence
                                in over 70 countries that works towards creating a sustainable world by using social and
                                market forces to protect nature and improve the lives of farmers and forest communities.
                            </div>

                            <div class="mt-3">
                                Some of the major measures initiated by Arishina Life Sciences as part of the Rain
                                forest alliance include promoting safe practices in cultivation, promoting
                                reforestation, pest control management through approved chemical application and border
                                crop and intercrop practices.
                            </div>
                            <div class="mt-3">
                                “Our efforts have always been focused on delivering responsible natural ingredients to
                                our consumers. Most companies resort to the procurement of sustainable produces from
                                rainforest alliance certified farms but care less about their own internal system. The
                                reason why we have opted to go the extra mile and certify Arishina’s own supply
                                management with rain forest alliance,” said Smiju MD, Managing Director, Arishina Life
                                Sciences.
                            </div>
                            <div class="mt-3">
                                Currently, the company has partnered with 2400 farmers from the regions across Karnataka
                                capable of producing 2000 hectares of turmeric and 200 hectares of chilly annually.
                                Currently, over 350 farms have been certified through Rain Forest Alliance programme.
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-5">
            <div class="container">
                <h3 class="fs-5 font-inter-medium mb-4">Related Articles</h3>
                <div class="row">
                    <div v-for="(item, i) in items" :key="i" class="col-12 col-lg-4 mb-4 col-md-6">
                        <article-card
                            :img="item.img"
                            :heading="item.heading"
                            :content="item.content"
                            :link="item.link"
                            :source="item.source"
                            :invert-img=" i===1? true : false"
                        ></article-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import BaseBreadcrumb from '@components/ui/BaseBreadcrumb';
// import NewsCard from '@components/home/NewsCard';
// import SubFooter from '@components/SubFooter';
// import axios from 'secure-axios';
// import urls from '../../data/urls';
import ArticleCard from '@components/ArticleCard';
// import img2 from '@/assets/img/logo/nia-logo-white.svg';
// import img3 from '@/assets/img/logo/logo-scaled.jpg';
// import img4 from '@/assets/img/logo/logo-nutraceutical.svg';

import img1 from '../../../assets/img/logo/logo-businessline.svg';
import img2 from '../../../assets/img/logo/nia-logo-white.svg';
import img3 from '../../../assets/img/logo/logo-scaled.jpg';
import img4 from '../../../assets/img/logo/logo-nutraceutical.svg';
import img5 from '../../../assets/img/logo/logo-agrospectrum.png';

export default {
    name: 'NewsArticlePage',
    components: { ArticleCard },
    props: {
        categ: {
            type: String,
            default: 'All'
        },
        heading: {
            type: String,
            default: 'Heading'
        },
        content: {
            type: String,
            default: 'Content'
        }
    },
    data () {
        return {
            items: [
                {
                    img: img1,
                    heading: 'Arishina Life Sciences gets Rainforest Alliance certification',
                    content: 'Kochi-based Arishina Life Sciences, which manufactures spices and botanical extracts, has received the Rainforest Alliance certification for its supply chain.',
                    link: '/news-businessline/',
                    source: 'www.thehindubusinessline.com'
                },
                {
                    img: img2,
                    heading: 'Indian botanical supplier Arishina Life Sciences addresses pesticides, traceability challenges',
                    content: 'The company’s supply chain of the turmeric that it uses to extract curcumin, has been certified by the Rainforest Alliance, an international non-governmental organisation, for its conformance with the alliance’s Sustainable Agriculture Standard.',
                    link: '/news-page-nutragradient/',
                    source: 'https://www.nutraingredients-asia.com/'
                },
                {
                    img: img3,
                    heading: 'Arishina Life Sciences mitigates pesticide contamination of spices with Rain Forest Alliance certification',
                    content: 'Kerala-based Arishina Life Sciences, manufacturers of spices and botanical extracts, have resorted to certifying its supply chain with the ‘Rainforest Alliance’ to promote sustainable agricultural practices and fight pesticide contamination of herbs and spices. ',
                    link: '/news-page-nufffoods-spectrum/',
                    source: 'https://nuffoodsspectrum.in/'
                },
                {
                    img: img4,
                    heading: 'Arishina earns rainforest alliance certification against pesticide',
                    content: 'Kerala-based Arishina Life Sciences, manufacturers of spices and botanical extracts, have resorted to certifying its supply chain with the ‘Rainforest Alliance’ to promote sustainable agricultural practices and fight pesticide contamination of herbs and spices. ',
                    link: '/news-page-nutraceutical-business/',
                    source: 'https://nutraceuticalbusinessreview.com/'
                },
                {
                    img: img5,
                    heading: 'Arishina Life Sciences teams up with Rainforest Alliance to eliminate pesticide contamination of spices',
                    content: 'Kerala-based Arishina Life Sciences- manufacturers of spices and botanical extracts has resorted to certifying its supply chain with the ‘Rain Forest Alliance’ as a move to promote sustainable agricultural practices and fight pesticide contamination of herbs and spices.',
                    link: '/news-page-agrospectrum/',
                    source: 'https://agrospectrumindia.com/'
                }
            ]
        };
    }
    // data () {
    //     return {
    //         details: '',
    //         id: this.$route.params.id,
    //         dataLoading: false
    //     };
    // }
    // mounted () {
    //     this.loadDetails();
    // },
    // methods: {
    //     async loadDetails () {
    //         this.dataLoading = true;
    //         const response = await axios.form(urls.website.newsDetails, { id: this.id });
    //         const json = response.data;
    //         if (json.error === false) {
    //             this.details = json.data;
    //         } else {
    //             this.$notify('Could not load data, Please try again later', 'Error', {
    //                 type: 'danger'
    //             });
    //         }
    //         this.dataLoading = false;
    //     }
    // }
};
</script>

<style scoped lang="scss">
.article-img {
    width: 100%;;
    position: relative;
    @media (min-width: 992px) {
        height: 60vh !important;
    }
}
</style>
