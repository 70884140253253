<template>
    <div class="bg-2">
        <section>
<!--            <base-video-banner height="full"></base-video-banner>-->
<!--            <home-banner></home-banner>-->
<!--            <base-video-banner></base-video-banner>-->
<!--            <base-banner class="d-sm-none" heading-align="center" heading="" height="full" :img="bannerImg" bg-position="top"></base-banner>-->
<!--            <base-banner class="d-lg-none" heading-align="bottom" heading="" height="full" :img="bannerMobileImg" bg-position="top"></base-banner>-->
            <home-banner-slider></home-banner-slider>
        </section>
        <section style="">
            <short-info-section></short-info-section>
        </section>
        <section id="partner-logos">
            <partner-logos></partner-logos>
        </section>
        <section id="features">
            <features-section></features-section>
        </section>
        <section>
            <home-media-coverage></home-media-coverage>
        </section>

        <!--        <div class="container">-->
        <!--            <logo-image-container>-->
        <!--                <template #image>-->
        <!--                    <img-->
        <!--                        src="https://uploads-ssl.webflow.com/5f85d0c81b6f5da2719b6b03/5f9ff6cdbd8ee96e119413f1_Logos_final_Levagen.png"-->
        <!--                        loading="lazy" alt="" class="hover-scale-up">-->
        <!--                </template>-->
        <!--            </logo-image-container>-->
        <!--            <div class="row">-->
        <!--                <div class="col-lg-3">-->
        <!--                    <article-card-->
        <!--                        img="https://images.unsplash.com/photo-1657214059264-99456d9aae24?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"-->
        <!--                        heading="Medlounges announces new wellness programme ‘Body Shaping and Contouring’"-->
        <!--                        content="Medlounges. founded by Dr. Joseph Thomas, follows a simple non-invasive body shaping procedure by involving pulsed ultrasound energy to destroy selective fat cells."-->
        <!--                        link="https://www.thehindubusinessline.com/news/medlounges-announces-new-wellness-programme-body-shaping-and-contouring/article37639011.ece"-->
        <!--                        source="The Hindu Businessline"-->
        <!--                    ></article-card>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>

</template>

<script>
// import LogoImageContainer from '@components/LogoImageContainer';
// import ArticleCard from '@components/ArticleCard';
// import VideoBackgroundBanner from '@components/home/VideoBackgroundBanner';
// import HomepageBanner from '@components/home/HomepageBanner';
import PartnerLogos from '@components/home/PartnerLogos';
import ShortInfoSection from '@components/home/ShortInfoSection';
import FeaturesSection from '@components/home/FeaturesSection';
// import HomeBanner from '@components/home/HomeBanner';
// import BaseBanner from '@components/BaseBanner';
// import HomeBanner from '@components/home/HomeBanner';
// import BaseVideoBanner from '@components/BaseVideoBanner';
// import HomepageBanner from '@components/home/HomepageBanner';
// import BaseVideoBanner from '@components/BaseVideoBanner';
import bannerImg from '../assets/home/HOME.jpg';
import bannerMobileImg from '../assets/home/banner-mobile-2.jpg';
import HomeBannerSlider from '@components/home/HomeBannerSlider';
import HomeMediaCoverage from '@components/home/HomeMediaCoverage';
// import bannerImg from '../assets/home/FORMAL.jpg';

export default {
    name: 'Home',
    components: { HomeMediaCoverage, HomeBannerSlider, FeaturesSection, ShortInfoSection, PartnerLogos },
    data () {
        return {
            bannerImg: bannerImg,
            bannerMobileImg: bannerMobileImg
        };
    }
};

</script>
<style scoped lang="scss">
.hover-scale-up {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    transform: scale(1);

    &:hover {
        transform: scale(1.1);
    }
}

.gridimg {
    max-width: 80%;
    margin-bottom: auto;
    padding-right: 0%;
    padding-left: 0%;
}
</style>
