<template>
    <section class="bg-info-details" :class="{'pt-4 pt-lg-5 pb-4': topPadding === 'lg', 'pt-4 pb-3':topPadding === 'sm'}">
        <!--        <div class="overlay-dark" v-if="districtInfo" @click="districtInfo = !districtInfo"></div>-->
        <div class="container">

            <div id="copy-text" class="row">
                <div class="col-12">
                    <!--                    <div class="fl-te-c mb-5">-->
                    <div class="mb-3" :class="{'ml-lg-8':design==='design-a'}">
                        <slot name="title">
                            <h4 class="text-uppercase text-primary font-lato-bold fs-lg-7" :class="{'text-center':design ==='design-b','text-center-res':design==='design-a'}">
                                {{ title }}
                            </h4>
                        </slot>
                        <!--                        <btn size="sm" icon="fa fa-caret-left" text="Back" @click="$emit('back')"/>-->
                    </div>
                </div>
                <div :class="{'col-md-6':design==='design-a','col-12':design === 'design-b', 'col-md-6 order-1 order-lg-2':design==='design-c'}">
                    <div class="mt-1">
                        <div class="w-100 fl-x" :class="[{'fl-x fl-j-c':design==='design-b', 'fl-j-c': centerImage}]">
                            <!--                            <img style="" src="../assets/products/product-1.jpg" alt=""-->
                            <!--                                 class="round-3 img-fluid">-->
                            <img style="" :src="image" alt=""
                                 class="round-3" :class="{'w-30': design === 'design-b','img-fluid':design ==='design-a' || design ==='design-c','b-orange':imageBorder}">
                        </div>
                    </div>
                </div>

                <div :class="{'col-md-6':design==='design-a','col-12':design === 'design-b', 'col-md-6 order-2 order-lg-1': design==='design-c'}">
                    <div class="mt-lg-0 mt-3">
                        <h4 class="fs-lg-2 text-uppercase font-lato-bold" :class="[priceClasses]"><span>{{price}}</span></h4>
                        <slot name="info"></slot>
                        <!--                        <p class="mt-3">-->
                        <!--                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aliquid dolorem dolores-->
                        <!--                            impedit! Adipisci consequuntur et exercitationem, facilis iste modi, nesciunt odit quia sint-->
                        <!--                            tempora ullam velit veniam voluptates. At debitis doloribus ex exercitationem fugiat quis-->
                        <!--                            soluta tenetur voluptates. A adipisci animi dolorem esse facilis fuga harum incidunt, ipsa-->
                        <!--                            magni molestiae natus odit pariatur possimus praesentium quam quis sed velit voluptatum.-->
                        <!--                            Amet asperiores consequuntur dicta dignissimos distinctio eius facere fugit hic iusto minus-->
                        <!--                            necessitatibus nobis officia, quasi velit voluptatibus. A adipisci aliquid atque blanditiis,-->
                        <!--                        </p>-->
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    name: 'ProductPageTemplate',
    props: {
        title: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        centerImage: {
            type: Boolean,
            default: true
        },
        design: {
            type: String,
            default: 'design-a'
        },
        price: {
            type: String,
            default: ''
        },
        priceClasses: {
            type: [String, Array],
            default: ''
        },
        topPadding: {
            type: String,
            default: 'lg'
        },
        imageBorder: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped>

</style>
