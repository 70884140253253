<template>
    <div>
        <div class="container py-5">
            <h4 class="text-uppercase text-primary font-lato-bold fs-lg-7">
                Brochures
            </h4>
            <ul class="mt-4 row list-unstyled">
                <li class="col-lg-4 col-md-6" v-for="(item,i) in products" :key="i">
                    <brochure-card :title="item.title" :link-url="item.link" :img="item.img"/>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
// import ProductItem from './ProductItem';
import BrochureCard from './BrochureCard';
// import BrochureCard2 from './BrochureCard2';
// import pdf from '../../../../pdfs/dummy.pdf';
import fbioticImg from './pdfs/1.fbiotic/f-biotic.jpg';
import lutepure from './pdfs/lutepure/Lutepure-01.jpg';
import phytores from './pdfs/phytores/phyto-res.jpeg';
import curodis from './pdfs/curodis-95/curo-dis95.jpg';
export default {
    name: 'OurProductsPage',
    components: { BrochureCard },
    data () {
        return {
            publicPath: process.env.BASE_URL + '/pdfs/',
            products: [
                {
                    title: 'F-biotic',
                    link: 'https://arishina.in/brochures/F-BIOTIC-LEAFLET.pdf',
                    img: fbioticImg
                },
                {
                    title: 'Lutepure',
                    link: 'https://arishina.in/brochures/LUTEPURE-LEAFLET.pdf',
                    img: lutepure
                },
                {
                    title: 'Phyto-Res',
                    link: 'https://arishina.in/brochures/PHYTO-RES-LEAFLET-PRINT.pdf',
                    img: phytores
                },
                {
                    title: 'Cur-dis-95',
                    link: 'https://arishina.in/brochures/CURCUMIN-LEAFLET-PRINT.pdf',
                    img: curodis
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
