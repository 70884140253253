<template>
    <div @scroll="bgPositionChange" style="max-height:40vh; background-repeat: no-repeat;" :style="{'background': 'url' + '(' + currentBgImage + ')', backgroundPosition : bgPosY + '%' + ' ' + '0%;' }" class="parallax-banner">
        <!--                <img src="../assets/about/banner-1.jpg" alt="" style="position:static;object-fit: fill; vertical-align: middle; overflow: visible; width: 100vw;max-height: 667.8125px;max-width: none;">-->
    </div>
</template>

<script>
export default {
    name: 'ParallaxBanner',
    props: {
        bgImageUrl: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            currentBgImage: this.bgImageUrl,
            bgPosY: 0
        };
    },
    mounted () {
        this.currentBgImage = this.bgImageUrl;
    },
    // computed: {
    //     bgImage () {
    //         return this.bgImageUrl;
    //     }
    // }
    methods: {
        bgPositionChange () {
            this.bgPosY += 20;
        }
    }
};
</script>

<style scoped>
.parallax-banner{

    max-height: 40vh;

    /*Create a parallax scrolling effect*/
    background-attachment: fixed;
    background-position: center !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding:0px;
}
</style>
