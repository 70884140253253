<template>
    <product-page-template title="" :image="productImg" price=" Spice And Herb Extract">
        <template #info>
            <p class="mt-3 txt-align">

                Spice extracts are an integral part of food processing as it offers higher replacement strength than
                ground spices and provides standardized flavors throughout the year, irrespective of seasonal variations
                in raw spice quality. Fresh-S™ is our range of spice extracts which has been extracted in an organic
                approach. The obtained extracts are available in both liquid & powder forms and have a fresh fruity
                smell when compared to the available extracts in the market. This proves beneficial to flavor makers who
                desire an instant burst of the smell & mouthfeel of the spice. The final product thus will be rich in
                fresh flavor & aroma and will create a palatable experience for the end consumers.
            <ul class="pl-5 mt-3 txt-align">

                <li>Chilly Green extract (Powder & liquid).</li>
                <li> Ginger Green extract (Powder & liquid)</li>
                <li>Pepper Green extract (Powder & liquid).</li>
                <li>Cardamom Green extract (Powder & liquid).</li>
            </ul>

            <p class="mb-2 mt-4  text-primary  font-weight-medium-3 text-primary  font-weight-medium">Applications</p>
            <ul class="pl-5 txt-align">
                <li> Seasonings</li>
                <li>Spice-blends</li>
                <li> Curries, Sauces</li>
                <li>Ready-to-Eat dishes</li>
                <li> Dips & Pickles.</li>
            </ul>

        </template>
    </product-page-template>
</template>

<script>
import ProductPageTemplate from '@components/ProductPageTemplate';
// import img from '../../../assets/products/product-1.jpg';
import productImg from '../../../assets/products/SPICE-AND-HERB.jpg';

export default {
    name: 'Spiceandherbextract',
    components: { ProductPageTemplate },
    data () {
        return {
            productImg: productImg
        };
    }
};
</script>

<style scoped>

</style>
