<!--<template>-->
<!--<div class="py-5">-->
<!--    <div class="container">-->
<!--        <div class="row">-->
<!--            <div class="col-12 col-lg-2" v-for="(img,i) in images" :key="i">-->
<!--                <logo-image-container>-->
<!--                    <template #image>-->
<!--                        <img :src="img"-->
<!--                            loading="lazy" alt="" class="hover-scale-up img-fluid">-->
<!--                    </template>-->
<!--                </logo-image-container>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<!--</template>-->

<script>
import LogoImageContainer from '@components/LogoImageContainer';
import img from '../../assets/partner-logos/partner-logo.png';
import img1 from '../../assets/partner-logos/partner-logo-1.png';
import img2 from '../../assets/partner-logos/partner-logo-2.png';
import img3 from '../../assets/partner-logos/partner-logo-3.png';
import img4 from '../../assets/partner-logos/partner-logo-4.png';
import img5 from '../../assets/partner-logos/partner-logo-5.png';
import img6 from '../../assets/partner-logos/partner-logo-6.png';
import img7 from '../../assets/partner-logos/partner-logo-7.png';
import img8 from '../../assets/partner-logos/partner-logo-8.png';
import img9 from '../../assets/partner-logos/partner-logo-9.png';
import img10 from '../../assets/partner-logos/partner-logo-10.png';
import img11 from '../../assets/partner-logos/partner-logo-11.png';
import img12 from '../../assets/partner-logos/partner-logo-12.png';
import img13 from '../../assets/partner-logos/partner-logo-13.png';
import img14 from '../../assets/partner-logos/partner-logo-14.png';
import img15 from '../../assets/partner-logos/partner-logo-15.png';
import img16 from '../../assets/partner-logos/partner-logo-16.png';
import img17 from '../../assets/partner-logos/partner-logo-17.png';
export default {
    name: 'PartnerLogos',
    components: { LogoImageContainer },
    data () {
        return {
            images: [img, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13,
                img14, img15, img16, img17
            ]
        };
    }
};
</script>

<style scoped>
.search-section {
    margin-top: 100vh;
}
</style>
