<template>
<div>

        <product-page-template title="" class="pb-3" :image="productImg"
                               price=" Other Botanical Extracts" design="design-a">
            <template #info>
                <div class="col-12 col-lg-12">
                    <table class="mt-4 font-lato-medium table table-bordered">
                        <tr>
                            <th>Product</th>
                            <th>Bio-makers</th>
                            <th>MOA</th>
                            <th>Health indicator</th>
                        </tr>
                        <tr>
                            <td>
                                amala extract
                            </td>
                            <td>
                                Polyphenols/Tannins 40%
                            </td>
                            <td>
                                Titration/HPLC
                            </td>
                            <td>
                                Anti-aging, Skin, Hair Care, Heart Health
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Ashwagandha extract
                            </td>
                            <td>
                                NLT 2.5% OR NLT 5% total withanolides
                            </td>
                            <td>
                                HPLC
                            </td>
                            <td>
                                Immunimodulatory,Adaptogen,Sexual Health
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Bacopa Extract
                            </td>
                            <td>
                                20%-40% Bacosides
                            </td>
                            <td>
                                HPLC
                            </td>
                            <td>
                                Memory Booster
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Boswellia extract
                            </td>
                            <td>
                                65% total Boswellic Acid
                            </td>
                            <td>
                                Titration/HPLC
                            </td>
                            <td>
                                Joint Health
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Cal.Sennosides
                            </td>
                            <td>
                                Sennosides 10-60%
                            </td>
                            <td>
                                HPLC/UV
                            </td>
                            <td>
                                Laxative
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Coleus Forskholi Extract
                            </td>
                            <td>
                                Forskolin 10-40%
                            </td>
                            <td>
                                HPLC
                            </td>
                            <td>
                                Weight Management
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Fenugreek Extract
                            </td>
                            <td>
                                Total Saponins 40% - 75%
                            </td>
                            <td>
                                Gravimetric
                            </td>
                            <td>
                                Blood Sugar Management
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Garcinia Cambogia Extract
                            </td>
                            <td>
                                HCA 50-60%
                            </td>
                            <td>
                                HPLC/AAS
                            </td>
                            <td>
                                Weight Management
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Ginger Extract
                            </td>
                            <td>
                                30% total Gingerols
                            </td>
                            <td>
                                HPLC
                            </td>
                            <td>
                                Digestive Health, Anti-Emetic
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Gotu Kola Extract
                            </td>
                            <td>
                                Total Triterpenes 10-20%
                            </td>
                            <td>
                                HPLC/Gravimetry
                            </td>
                            <td>
                                Skin Care, Memory Support
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Green Coffee bean extract
                            </td>
                            <td>
                                CGA 50% & Caffeine NMT 1%
                            </td>
                            <td>
                                Gravimetric
                            </td>
                            <td>
                                Gravimetric
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Green Tea extract
                            </td>
                            <td>
                                50% Polyphenols
                            </td>
                            <td>
                                UV
                            </td>
                            <td>
                                Anti-oxidant, Skin & Hair Care
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Guggul Extract
                            </td>
                            <td>
                                Guggulstereones NLT 2.5%
                            </td>
                            <td>
                                HPLC
                            </td>
                            <td>
                                Anti-Inflammatory
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Moringa Extract
                            </td>
                            <td>
                                Saponins 10%-20%
                            </td>
                            <td>
                                Gravimetric
                            </td>
                            <td>
                                Immunimodulatory
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Mormodica Extract
                            </td>
                            <td>
                                Bitter principles 2.5%-15%
                            </td>
                            <td>
                                gravimetry
                            </td>
                            <td>
                                Blood Sugar Management
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Mucuna Extract
                            </td>
                            <td>
                                L-DOPA 10%-50%
                            </td>
                            <td>
                                HPLC/IP
                            </td>
                            <td>
                                Men's Health
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Tribulus Terrestris extract
                            </td>
                            <td>
                                Saponins 40%-60%
                            </td>
                            <td>
                                Gravimetric
                            </td>
                            <td>
                                Sports Health, Cardio Health, Sexual Health
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Tulsi Extract
                            </td>
                            <td>
                                Ursolic acid 2.5%
                            </td>
                            <td>
                                HPLC
                            </td>
                            <td>
                                Anti-pyretic,Anti-Cough,Anti-Oxidant
                            </td>
                        </tr>

                    </table>
                </div>
            </template>
        </product-page-template>
    </div>
</template>

<script>
import ProductPageTemplate from '@components/ProductPageTemplate';
// import img from '../../../assets/products/product-1.jpg';
// import img2 from '../../../assets/products/curcumin.jpg';
// import img3 from '../../../assets/products/curdis.jpg';
import productImg from '../../../assets/products/STANDARD.jpg';

export default {
    name: 'Standard-nutra-ingredient',
    components: { ProductPageTemplate },
    data () {
        return {
            productImg: productImg

        };
    }
};
</script>

<style scoped>

table {
    border-collapse: collapse;
    width: 100%;
    /*font-family: helvetica-light;*/
    font-size: 14px;
    /*color: darkolivegreen;*/
}

td, th {
    /*border: 1px solid darkolivegreen;*/
    /*border: 1px solid darkolivegreen;*/
    padding: 4px;
    text-align: left;
}

@media (max-width: 750px) {
    table {
        width: 100%;
        padding: 0px;
        font-sixe: 12px;
    }

    td, th {
        border: 1px solid darkolivegreen;
        padding: 1px;
        text-align: left;
        width: 100%;
    }
    div{
      overflow-x: auto;
   }
}
</style>
