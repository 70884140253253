<template>
    <div class="container py-5">
        <div class="row">
            <div class="col-md-8">
                <h3 class="text-primary font-lato-bold mb-3">Cur-Liv Technology </h3>
                <p class="txt-align">Cur-Liv is a unique product by Arishina Life Sciences enriched with upto 10% of
                    curcuminoids , upto 10 % of ar-turmerones , manufactured using Arishina’s proprietary Natural
                    Complex Sandwich technology .</p>
                <p class="txt-align pt-2 mb-2">Cur-Liv is a cost effective
                    feed additive solution to address all the heath needs of Livestock & Pet animals</p>
                <ul class="pl-3">
                    <li class="txt-align pt-2 ">Its available in both powder ( Cur-Liv 2.5%) & liquid
                        ( Cur-Liv 10%) formats
                    </li>
                    <li class="txt-align pt-2 ">Most bio-available turmeric complex manufactured for
                        optimum animal health
                    </li>
                </ul>
            </div>
            <div class="col-md-4">
                <div class="fl-x-cc h-100p mt-4 mt-lg-0">
                    <img src="../assets/cur-liv/curliv-technology.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-6 mt-4">
                <h3 class="text-primary font-lato-bold pt-3">Natural Complex Sandwich Technology</h3>
                <p class="txt-align pt-2"><span
                    class="font-lato-semibold">Natural Complex Sandwich Technology</span> is
                    a proprietary manufacturing process developed by Arishina to
                    preserve the phytomolecules of
                    a plant in its natural state , entrapped within the trace mineral complex of the plant.</p>

                <p class="txt-align font-lato-semibold pt-2">Cur-Liv is manufactured using this NCS technology
                    which optimizes the bio-availability of the turmeric complex.</p>
                <p class="pt-2">Cur-Liv constitutes of</p>
                <ul class="pl-3 ">
                    <li>1% - 10% curcumin</li>
                    <li class="pt-2">1 % - 10% of total turmerones</li>
                    <li class="pt-2">Trace minerals like Zn, Cu etc</li>
                    <li class="pt-2">Water soluble poly saccharides</li>
                </ul>
            </div>
            <div class="col-md-6 mt-4">
                <img src="../assets/cur-liv/natural-complex-sandwitch.png" class="w-100p pt-lg-4 pt-2">
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'CurLivTechnologyPage'
};
</script>

<style scoped>

</style>
