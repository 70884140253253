<template>
    <div class="about">
        <section>
            <base-banner heading="Supply Chain" :img="banner3" bg-position="center" class=""
                         height="half"></base-banner>
        </section>
        <div class="container py-5">
            <div class="row">
                <section class="pb-3 col-12 col-lg-6 order-2 order-lg-1">
                    <img src="../assets/about/supplychain2.jpg" class="img-fluid">
                </section>
                <section class="col-12 col-lg-6 order-1 order-lg-2" style="">
                    <base-short-info heading="SUPPLY CHAIN MANAGEMENT">
                        <template #content>
                            <p class="fs-1 txt-align">
                                At the heart of Arishina lies its robust supply chain system with precision point
                                management
                                that serves the streamlined process of delivering solutions to its global customer touch
                                points. The company already boasts of an ongoing strategic relationship with over 2400
                                Indian farmers equipped to deliver nearly 2400 hectares of turmeric cultivation
                                (equivalent to 15000 MT turmeric rhizome) and over 200 hectares of chilly cultivation
                                (equivalent to 1000 MT dry chilly). The livelihood of farmers is protected by
                                implementing intelligent farming practices steered and guided by Arishina’s direct team
                                members working closely with every farmer. Intercrops cultivation
                                of chilly and beetroot ensures every farmer gets a fair share of revenue around the
                                year.
                            </p>
                        </template>
                    </base-short-info>
                </section>
                <section class=" py-4 col-12 order-3">
                    <base-short-info heading="SUPPLY CHAIN INITIATIVES">
                        <template #content>

                            <ol class="fs-1 txt-align">
                                <li class="mb-2"> Active engagement of Arishina personnel for back-end calling and direct farmer
                                    interaction.
                                </li>
                                <!--                                <li> Implementation of farm tagging and land audit to monitor and establish traceability-->
                                <!--                                    of-->
                                <!--                                    deliverables with the help of advanced farm management software.-->
                                <!--                                </li>-->
                                <li class="mb-2">
                                    Farm-to-product traceability is ensured with the implementation of ‘Radio Frequency
                                    Identification ( RFID) technology since 2021.
                                </li>
                                <li class="mb-2"> Soil testing.</li>
                                <li>Agriculture activity mapping ( right from land preparation to sowing, weeding,
                                    spraying,
                                    irrigation, harvest, post-harvest and yield estimation).
                                </li>
                            </ol>

                        </template>
                    </base-short-info>

                </section>

            </div>
        </div>

        <!--        <section class="">-->
        <!--            <parallax-banner :bg-image-url="banner2"></parallax-banner>-->
        <!--        </section>-->
        <!--        <section class="">-->
        <!--            <base-short-info heading="Our Ayurvedic Roots.">-->
        <!--                <template #content>-->
        <!--                    <p class="text-primary fs-1 font-weight-light">-->
        <!--                        For 6,000 years, the natural system known as Ayurveda has supported the health needs of-->
        <!--                        individuals through natural botanicals. Ayurveda is a science of life (Ayur = life, Veda =-->
        <!--                        science) that offers a systematic approach to wellness that helps human beings realize their-->
        <!--                        full potential. We respect the wisdom inherent in Ayurveda and work closely with the best-->
        <!--                        scientists of Ayurvedic institutes to make this wisdom available to a global market.-->
        <!--                    </p>-->
        <!--                </template>-->
        <!--            </base-short-info>-->
        <!--        </section>-->
        <!--        <section class="">-->
        <!--            <parallax-banner :bg-image-url="banner2"></parallax-banner>-->
        <!--        </section>-->
        <!--        <section class="">-->
        <!--            <base-short-info heading="Quality Begins on the Farm.">-->
        <!--                <template #content>-->
        <!--                    <p class="text-primary fs-1 font-weight-light">-->
        <!--                        Gencor botanicals are grown under the close supervision of our technical team and according to-->
        <!--                        strict quality-assurance processes. For instance, our herbs are always dried in the shade, so-->
        <!--                        they maintain their phytochemical content. All our extracts comply with the California Prop-65-->
        <!--                        law for heavy metals and our single-herb standardized extracts are free from all known sources-->
        <!--                        of contamination (including other herbs).-->
        <!--                    </p>-->
        <!--                </template>-->
        <!--            </base-short-info>-->
        <!--        </section>-->
    </div>
</template>
<script>

// import ParallaxBanner from '@components/ParallaxBanner';
import banner1 from '../assets/about/banner-1.jpg';
import banner2 from '../assets/about/banner-2.jpg';
// import BaseVideoBanner from '@components/BaseVideoBanner';
import BaseShortInfo from '@components/BaseShortInfo';
import BaseBanner from '@components/BaseBanner';
import banner3 from '../assets/about/supplychain.jpg';
import banner4 from '../assets/about/supplychain2.jpg';

export default {
    name: 'SupplyChainPage',
    components: { BaseBanner, BaseShortInfo },
    data () {
        return {
            banner1: banner1,
            banner2: banner2,
            banner3: banner3,
            banner4: banner4
        };
    }
};
</script>

<style scoped lang="scss">
.parallax-banner {
    background: url("../assets/about/banner-1.jpg");

    min-height: 40vh;

    //Create a parallax scrolling effect
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
