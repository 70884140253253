<template>
    <product-page-template title="" :image="img2" price="Natural Essential Oils (Abs- Frag™)">
        <template #info>
            <p class="mt-3 txt-align">Most of the essential oils produced by conventional steam distillation lack a rounded
                profile as the lower notes often get evaporated during the pre-treatment stage itself. Abs-Frag TM is
                our range of essential oils manufactured by Arishina’s own proprietary steam distillation method which
                ensures that all the notes remain intact in the essential oil. The final oil thus obtained has all the
                notes (base+middle +top) and gives a complete fragrance profile of the spice/flower thus enhancing the
                further end applications of the essential oil.

            </p>
            <p class="mt-3   font-weight-medium-3 font-lato-bold  font-weight-medium">PRODUCT RANGE</p>
            <ul class="pl-5">

                <li>Ajowan</li>
                <li> Galangal</li>
                <li> Cinnamon.</li>
                <li> Cassia</li>
                <li> Lemongrass</li>
                <li> Holy Basil</li>
                <li> Black Pepper</li>
                <li> Clove</li>
                <li> Mace</li>
                <li> Coriander</li>
                <li> Cardamom</li>
                <li>Juniper Berry</li>
                <li> Nutmeg</li>
            </ul>

        </template>
    </product-page-template>
</template>

<script>
import ProductPageTemplate from '@components/ProductPageTemplate';
import img from '../../../assets/products/product-1.jpg';
import img2 from '../../../assets/products/naturalessentialoil.jpg';

export default {
    name: 'Naturalessentialoil',
    components: { ProductPageTemplate },
    data () {
        return {
            productImg: img,
            img2: img2
        };
    }
};
</script>

<style scoped>

</style>
