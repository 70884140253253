<template>
    <div class="about">
        <section>
            <base-banner heading="Sustainability" :img="banner3" bg-position="center" class=""
                         height="half"></base-banner>

        </section>
        <section class="pt-5" style="">
            <base-short-info heading="Sustainable Farming">
                <template #content>
                    <ul class="ml-3 ml-lg-5 txt-align">
                        <li class="mb-2">
                            Sustainable agriculture is all about farming in sustainable ways that meet society's present
                            food and textile needs, without compromising on the ability of current or future generations
                            to meet their needs. It is an integrated system of farming practices that will over a period
                            of time make the most efficient use of nonrenewable and on-farm resources and eventually
                            enhance the quality of life for farmers and society as a whole. Farmers associated with
                            Arishina are guided right through the farming process starting from land preparation, to
                            sowing, weeding, irrigation, harvest, post-harvest, and also towards yield estimation. The
                            extensive scientific farming regime at Arishina includes
                        </li>
                        <li class="mb-2">Soil testing</li>
                        <li class="mb-2">Cluster management for interactive farming</li>
                        <li class="mb-2">Seed selection support</li>
                        <li class="mb-2">Farm/Farmer screening and registration.</li>
                        <li class="mb-2">GPS tagging and land audit.</li>
                        <li class="mb-2">Prevention of Eutrophication</li>
                        <li>Activity mapping in farm management software</li>
                    </ul>
                </template>
            </base-short-info>
        </section>
        <section class="mt-5">
            <!--            <parallax-banner :bg-image-url="banner1"></parallax-banner>-->
            <base-banner heading="" class="" bg-position="bottom" :img="rainForestImg"
                         height="sm"></base-banner>
            <base-short-info heading="RAIN FOREST ALLIANCE" class="mt-4">
                <template #content>
                    <p class="txt-align">
                        The Rainforest Alliance is a product-oriented multi-stakeholder governance group that aims at
                        combining the interests of companies, farmers, foresters, communities, and consumers to produce
                        sustainable and harmonious goods. Arishina Life Sciences is the first organization in South East
                        Asia to get rainforest certification for its supply chain. With ‘Rain Forest Alliance’ the
                        company
                        aims to create a sustainable community regime that promotes and protects the life and interests
                        of
                        farmers in India. A collective movement currently appreciated and promoted globally, the
                        rainforest
                        alliance ensures the symbiotic co-existence of farmers, companies, foresters, and consumers with
                        a
                        vision to produce harmonious and sustainable goods.
                    </p>
                    <p class="mt-3">The practices under rain forest alliance at Arishina include </p>
                    <ul class="ml-3 ml-lg-5 mt-3 txt-align">
                        <li class="mb-2">
                            Promoting safe practices in cultivation and PPE usage
                        </li>
                        <li class="mb-2">Promote reforestation and related practices.</li>
                        <li class="mb-2">Pest control management through approved chemical application.</li>
                        <li class="mb-2">Border crop and intercrop practices.</li>
                        <li class="mb-2">Organizing health cultivation practice awareness programs.</li>
                        <li class="mb-2">Conducting health camps and medical support.</li>
                        <li class="mb-2">Create value-addition of the farm output for the maximum benefit of the
                            farmer
                        </li>
                    </ul>
                </template>
            </base-short-info>
        </section>
        <section class="py-4">
            <base-banner heading="" class="" bg-position="center" :img="RegenerativeImg"
                         height="sm"></base-banner>
            <base-short-info class="mt-4" heading="Regenerative Agricultural Practices " :content-padding="false">
                <template #content>
                    <p class="fs-1 txt-align">
                        At Arishina, we believe that the best way to improve and effectively manage any kind of
                        cultivation is by improving the quality of the soil. Undoubtedly soil is truly the heart of
                        agriculture. Right from inception, the team at Arishina has been keen on adhering to
                        ‘Regenerative Agricultural Practices’ (RAP)a global approach that aims to improve the fertility
                        of the soil by increasing its organic composition. With a collaborative movement involving
                        farmers, RAP stresses relooking at the current farming approaches and thereby creating a better
                        balance and replenishing the soil texture with permanent plant cover.
                    </p>
                </template>
            </base-short-info>
        </section>
        <section class="pb-4">
            <!--            <parallax-banner :bg-image-url="banner2"></parallax-banner>-->
            <div class="container">
                <div class="row pb-4">
                    <div class="col-lg-12">
                         <iframe class="w-100p i-frame-video-lg" src="https://www.youtube.com/embed/b7TD9D4Ozls" title="SUSTAINABIITY" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen></iframe>
                    </div>
                </div>
            </div>

        </section>
        <!--        <section class="">-->
        <!--            <base-short-info heading="Our Ayurvedic Roots.">-->
        <!--                <template #content>-->
        <!--                    <p class="text-primary fs-1 font-weight-light">-->
        <!--                        For 6,000 years, the natural system known as Ayurveda has supported the health needs of-->
        <!--                        individuals through natural botanicals. Ayurveda is a science of life (Ayur = life, Veda =-->
        <!--                        science) that offers a systematic approach to wellness that helps human beings realize their-->
        <!--                        full potential. We respect the wisdom inherent in Ayurveda and work closely with the best-->
        <!--                        scientists of Ayurvedic institutes to make this wisdom available to a global market.-->
        <!--                    </p>-->
        <!--                </template>-->
        <!--            </base-short-info>-->
        <!--        </section>-->
        <!--        <section class="">-->
        <!--            <parallax-banner :bg-image-url="banner2"></parallax-banner>-->
        <!--        </section>-->
        <!--        <section class="">-->
        <!--            <base-short-info heading="Quality Begins on the Farm.">-->
        <!--                <template #content>-->
        <!--                    <p class="text-primary fs-1 font-weight-light">-->
        <!--                        Gencor botanicals are grown under the close supervision of our technical team and according to-->
        <!--                        strict quality-assurance processes. For instance, our herbs are always dried in the shade, so-->
        <!--                        they maintain their phytochemical content. All our extracts comply with the California Prop-65-->
        <!--                        law for heavy metals and our single-herb standardized extracts are free from all known sources-->
        <!--                        of contamination (including other herbs).-->
        <!--                    </p>-->
        <!--                </template>-->
        <!--            </base-short-info>-->
        <!--        </section>-->
    </div>
</template>
<script>

// import ParallaxBanner from '@components/ParallaxBanner';
import banner1 from '../assets/about/banner-1.jpg';
import banner2 from '../assets/about/banner-2.jpg';
// import BaseVideoBanner from '@components/BaseVideoBanner';
import BaseShortInfo from '@components/BaseShortInfo';
import BaseBanner from '@components/BaseBanner';
import banner3 from '../assets/about/SUSTAINABILITY.jpg';
import rainForestImg from '../assets/sustainability/RAINFOREST-ALLIANCE-DESKTOP.jpg';
import RegenerativeImg from '../assets/sustainability/REGENERATIVE-AGRICULTURAL-PRACTICES-DESKTOP.jpg';

export default {
    name: 'SustainabilityPage',
    components: { BaseBanner, BaseShortInfo },
    data () {
        return {
            banner1: banner1,
            banner2: banner2,
            banner3: banner3,
            rainForestImg: rainForestImg,
            RegenerativeImg: RegenerativeImg
        };
    }
};
</script>

<style scoped lang="scss">
    .parallax-banner {
        background: url("../assets/about/banner-1.jpg");

        min-height: 40vh;

        //Create a parallax scrolling effect
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
</style>
