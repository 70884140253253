<template>
    <section class="py-5">
        <div class="container">
            <h2 class="text-center font-weight-semibold mb-5">Media Coverage</h2>

            <swiper class="swiper" :options="swiperOption">
                <swiper-slide>
                    <router-link to="/media/">
                        <img src="../../assets/img/logo/logo-businessline.svg" class="container-fluid" alt="">
                    </router-link>
                </swiper-slide>
                <swiper-slide>
                    <router-link to="/media/">
                        <img src="../../assets/img/logo/nia-logo-white.svg" class="invert-img container-fluid" alt="">
                    </router-link>
                </swiper-slide>
                <swiper-slide>
                    <router-link to="/media/">
                        <img src="../../assets/img/logo/logo-agrospectrum.png" class="container-fluid                                                                                                                                                                              " alt="">
                    </router-link>
                </swiper-slide>
                <swiper-slide>
                    <router-link to="/media/">
                        <img src="../../assets/img/logo/logo-nutraceutical.svg" class="container-fluid" alt="">
                    </router-link>
                </swiper-slide>
                <swiper-slide>
                    <router-link to="/media/">
                        <img src="../../assets/img/logo/logo-scaled.jpg" class="container-fluid" alt="">
                    </router-link>
                </swiper-slide>
                <swiper-slide>
                    <router-link to="/media/">
                        <img src="../../assets/img/logo/DeccanChronicle_Logo.jpg" class="container-fluid" alt="">
                    </router-link>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

import img1 from '../../assets/img/logo/logo-businessline.svg';
import img2 from '../../assets/img/logo/nia-logo-white.svg';
import img3 from '../../assets/img/logo/logo-agrospectrum.png';
import img4 from '../../assets/img/logo/logo-nutraceutical.svg';
import img5 from '../../assets/img/logo/logo-scaled.jpg';
import img6 from '../../assets/img/logo/DeccanChronicle_Logo.jpg';
// import img2 from '../../assets/img/logo/l';
// import img2 from '../../../assets/img/partners/1.png';
// import img3 from '../../../assets/img/partners/02.png';

export default {
    name: 'HomeMediaCoverage',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            swiperOption: {
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
                breakpoints: {
                    1200: {
                        slidesPerView: 4
                    },
                    768: {
                        slidesPerView: 3
                    },
                    580: {
                        slidesPerView: 2
                    },
                    320: {
                        slidesPerView: 1
                    }
                }
            },
            images: [
                img1, img2, img3, img4, img5, img6
            ]
        };
    }
};
</script>

<style scoped lang="scss">

.img-grayscale {
    -webkit-filter: grayscale(1);
    -webkit-transition: all .2s ease-in-out;
    opacity: .5;

    &:hover {
        filter: unset;
        -webkit-filter: grayscale(0);
        opacity: 1;
    }
}

.invert-img{
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}
</style>
