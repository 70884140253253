<template>
    <div>
        <slot name="image"></slot>
    </div>
</template>

<script>
export default {
    name: 'LogoImageContainer'
};
</script>

<style scoped lang="scss">
.hover-scale-up{
    transition:all 0.3s ease-in-out;
    cursor: pointer;
    transform: scale(1);
    &:hover{
        transform: scale(1.1);
    }
}
</style>
