<template>
    <div class="about">
        <section>
            <base-video-banner heading="Eye Health" class="mt-6" height="half"></base-video-banner>
        </section>
        <section class="" style="margin-top:60vh;">
            <base-short-info heading="Less Aging. More Living.">
                <template #content>
                    <p class="text-primary fs-1">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aut consequatur, corporis
                        dicta dolore esse non quam rem reprehenderit vel.
                    </p>
                </template>
            </base-short-info>
        </section>
        <section class="">
            <parallax-banner :bg-image-url="banner1"></parallax-banner>
        </section>
        <section class="">
            <base-short-info heading="Our Commitment to Science.">
                <template #content>
                    <p class="text-primary fs-1 font-weight-light">
                        Our herbal ingredients have been clinically studied and shown effective in both animal and human
                        clinical trials. Many of our clinical trials are peer-reviewed, double-blind controlled trials
                        with impressive results. In addition to formal research tests, our ingredients go through
                        rigorous scientific analysis with our state-of-the-art testing equipment under GLP-certified
                        conditions.
                    </p>
                </template>
            </base-short-info>
        </section>
        <section class="">
            <parallax-banner :bg-image-url="banner2"></parallax-banner>
        </section>
        <section class="">
            <base-short-info heading="Our Ayurvedic Roots.">
                <template #content>
                    <p class="fs-1">
                        For 6,000 years, the natural system known as Ayurveda has supported the health needs of
                        individuals through natural botanicals. Ayurveda is a science of life (Ayur = life, Veda =
                        science) that offers a systematic approach to wellness that helps human beings realize their
                        full potential. We respect the wisdom inherent in Ayurveda and work closely with the best
                        scientists of Ayurvedic institutes to make this wisdom available to a global market.
                    </p>
                </template>
            </base-short-info>
        </section>
        <section class="">
            <parallax-banner :bg-image-url="banner2"></parallax-banner>
        </section>
        <section class="">
            <base-short-info heading="Quality Begins on the Farm.">
                <template #content>
                    <p class=" fs-1 ">
                        Gencor botanicals are grown under the close supervision of our technical team and according to
                        strict quality-assurance processes. For instance, our herbs are always dried in the shade, so
                        they maintain their phytochemical content. All our extracts comply with the California Prop-65
                        law for heavy metals and our single-herb standardized extracts are free from all known sources
                        of contamination (including other herbs).
                    </p>
                </template>
            </base-short-info>
        </section>
    </div>
</template>
<script>

import ParallaxBanner from '@components/ParallaxBanner';
import banner1 from '../../assets/about/banner-1.jpg';
import banner2 from '../../assets/about/banner-2.jpg';
import BaseVideoBanner from '@components/BaseVideoBanner';
import BaseShortInfo from '@components/BaseShortInfo';

export default {
    name: 'SustainabilityPage',
    components: { BaseShortInfo, BaseVideoBanner, ParallaxBanner },
    data () {
        return {
            banner1: banner1,
            banner2: banner2
        };
    }
};
</script>

<style scoped lang="scss">
.parallax-banner {
    //background: url("../../assets/about/banner-1.jpg");

    min-height: 40vh;

    //Create a parallax scrolling effect
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
