<template>
    <div>
        <product-page-template title="cur-o-stain™" class="pb-3 img-fluid" :image="img4" price="Minimise Stain. Maximise Efficiency">
            <template #title>
                <div class="sm-fl-x sm-fl-j-c md-d-block">
                    <img src="../assets/curo-stain/CUROSTAIN-logo.svg" class="icon-md">
                </div>
            </template>
            <template #info>
                <p class="mt-3 txt-align">
                    Cur-O-Stain™ is a specialized product designed to prevent curcuminoids from staining equipment and
                    surfaces. Cur-O-Stain™ is a non-staining Turmeric Extract standardized to 95% curcuminoids. The
                    free-flowing property of Cur-O-Stain™ derived out of its granular nature is the main reason for
                    keeping the equipment and machinery or any contact surfaces unstained during its production and
                    material handling. For supplement manufacturers using Cur-O-Stain™ involves less change-over time
                    which results in improved operational and technical efficiencies and thus reduces the overall cost
                    of production and at the same time, is an eco-friendly option for curcumin.</p>
                <p class="mb-2 mt-4 font-weight-medium-3 font-lato-bold font-weight-medium">
                    ADVANTAGES</p>
                <ul class="pl-5">
                    <li> Minimise Operational Cost</li>
                    <li> Hassle Free Handling</li>
                    <li>Improved Efficiency</li>
                    <li> Improved Convenience</li>
                    <li> Offers Anti-Lumping Capabilities</li>
                    <li> Minimal Surface Stickiness</li>
                    <li> 100% Natural</li>
                </ul>
            </template>
        </product-page-template>
        <!--        Video-->
        <div>
            <div class="container py-lg-5 pb-5">
                <div class="fl-x-cc m-lg-5 mt-md-3 mt-lg-0">
                    <iframe class="w-100p i-frame-video-lg" src="https://www.youtube.com/embed/adyJ5GEIDsA" title="Cur-0-Stain" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductPageTemplate from '@components/ProductPageTemplate';
// import img from '../../../assets/products/product-1.jpg';
import img2 from '../../../assets/products/curcumin.jpg';
import img3 from '../../../assets/products/curdis.jpg';
// import img4 from '../../../assets/products/curostain.jpg';
import img4 from '../assets/curo-stain/curo-stain.jpg';

export default {
    name: 'Cur-o-stain',
    components: { ProductPageTemplate },
    data () {
        return {
            productImg: img2,
            img3: img3,
            img4: img4

        };
    }
};
</script>
<style scoped>

</style>
