<template>
    <div class="about">
        <section>
            <!--            <base-video-banner heading="About Us" class="mt-6" height="half"></base-video-banner>-->
            <base-banner heading="About Us" class="" bg-position="bottom" :img="topBanner"
                         height="half"></base-banner>
        </section>
<!--        <section class="" style="margin-top:60vh;">-->
<!--        <section class="txt-align" style="">-->
<!--            <base-short-info heading ="Arishina believes in nurturing a culture of superior quality and-->
        <!--        <section class="" style="margin-top:60vh;">-->
        <section class="pt-4" style="">
            <base-short-info heading="Arishina believes in nurturing a culture of superior quality and
                        commitment to delivering excellence. Our mission is to bring out the
                        best of nature for the betterment of health and humanity.">
                <template #content>
                    <p class="text-primary fs-2">
                        <!--                        Arishina believes in nurturing a culture of superior quality and-->
                        <!--                        commitment to delivering excellence. Our mission is to bring out the-->
                        <!--                        best of nature for the betterment of health and humanity.-->
                    </p>
                </template>
            </base-short-info>
        </section>
<!--        <section class="" style="height: 40vh;">-->
<!--            <parallax-banner :bg-image-url="banner3"></parallax-banner>-->
        <section class="" style="">
<!--            <parallax-banner :bg-image-url="banner3"></parallax-banner>-->
            <base-banner heading="" class="mt-4" bg-position="center" :img="banner3"
                         height="sm"></base-banner>

        </section>
        <section class="py-3">
            <base-short-info heading="">
                <!--                 <base-short-info heading="Our Commitment to Science.">-->
                <template #content>
                    <p class="fs-1 txt-align pt-0 mt-0">
                        Embracing health in the most natural way refreshes and rejuvenates our
                        body, mind, and soul. Arishina Life Sciences Pvt. Ltd. works truly on this
                        philosophy, by integrating nature and health through the natural processing
                        of spices, herbs, and natural extracts for a better life. Conceived and
                        founded with a convention of extracting health from nature’s finest
                        ingredients, Arishina has made a brand name for itself in the nutraceutical
                        and dietary supplement industry in India and abroad. The company is listed
                        among fast-growing Indian enterprises that focus on delivering result-based,
                        efficacy-proven, natural nutritional choices to its global clientele.
                        The product offering from the company includes nutraceutical ingredients,
                        spice extracts, essential oils, natural anti-oxidants, and natural colors.
                        Arishina has its central operational facility strategically located at India’s
                        most promising agriculture hamlet ‘Gundlupet’, known as ‘The Flower Pot Of
                        India’. Gundlupet brings an ideal blend of suitable weather conditions and
                        fertile topography, making it the most sought-after agricultural hub for growing
                        cash crops like turmeric, marigold, sunflower, banana, etc.

                    </p>
                </template>
            </base-short-info>
        </section>
        <!--        <section class="">-->
        <!--            <parallax-banner :bg-image-url="banner2"></parallax-banner>-->
        <!--        </section>-->
        <!--        <section class="">-->
        <!--            <base-short-info heading="Our Ayurvedic Roots.">-->
        <!--                <template #content>-->
        <!--                    <p class="text-primary fs-1">-->
        <!--                        For 6,000 years, the natural system known as Ayurveda has supported the health needs of-->
        <!--                        individuals through natural botanicals. Ayurveda is a science of life (Ayur = life, Veda =-->
        <!--                        science) that offers a systematic approach to wellness that helps human beings realize their-->
        <!--                        full potential. We respect the wisdom inherent in Ayurveda and work closely with the best-->
        <!--                        scientists of Ayurvedic institutes to make this wisdom available to a global market.-->
        <!--                    </p>-->
        <!--                </template>-->
        <!--            </base-short-info>-->
        <!--        </section>-->
        <!--        <section class="">-->
        <!--            <parallax-banner :bg-image-url="banner2"></parallax-banner>-->
        <!--        </section>-->
        <!--        <section class="">-->
        <!--            <base-short-info heading="Quality Begins on the Farm.">-->
        <!--                <template #content>-->
        <!--                    <p class="text-primary fs-1">-->
        <!--                        Gencor botanicals are grown under the close supervision of our technical team and according to-->
        <!--                        strict quality-assurance processes. For instance, our herbs are always dried in the shade, so-->
        <!--                        they maintain their phytochemical content. All our extracts comply with the California Prop-65-->
        <!--                        law for heavy metals and our single-herb standardized extracts are free from all known sources-->
        <!--                        of contamination (including other herbs).-->
        <!--                    </p>-->
        <!--                </template>-->
        <!--            </base-short-info>-->
        <!--        </section>-->
    </div>
</template>
<script>

// import ParallaxBanner from '@components/ParallaxBanner';
import banner1 from '../assets/about/banner-1.jpg';
import topBanner from '../assets/about/ABOUT-US.jpg';
import banner2 from '../assets/about/banner-2.jpg';
// import BaseVideoBanner from '@components/BaseVideoBanner';
import banner3 from '../assets/about/aboutus2.jpg';
// import BaseVideoBanner from '@components/BaseVideoBanner';
import BaseShortInfo from '@components/BaseShortInfo';
import BaseBanner from '@components/BaseBanner';

export default {
    name: 'About',
    components: { BaseBanner, BaseShortInfo },
    data () {
        return {
            banner1: banner1,
            banner2: banner2,
            banner3: banner3,
            topBanner: topBanner
        };
    }
};
</script>

<style scoped lang="scss">
.parallax-banner {
    background: url("../assets/about/banner-1.jpg");

    min-height: 40vh;

    //Create a parallax scrolling effect
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

</style>
