<template>
    <div class="container py-5">
        <div class="row ">
            <div class="col-lg-12 ">
                <!--                <h3 class="text-primary font-lato-bold mb-3">D-Sperse Technology </h3>-->
                <div class="sm-fl-x sm-fl-j-c md-d-block ml-n4">
                    <img src="./assets/d-sperse/d-sperse-logo.jpg" class="icon-lg">
                </div>
            </div>
            <div class="col-md-8 ">
                <p class="txt-align">D-Sperse is a novel drug delivery technology used to enhance the solubility and bioavailability
                    of poorly water-soluble drugs. It is based on binding the API molecules with food grade surfactants
                    ,
                    under high pressure processing ,
                    to create a more bio-available , even dispersion of the hydrophobic API molecules in water.</p>
                <p class="pt-2 txt-align">Molecular core binding theory is the heart of D-Sperse technology . The hydrophobic drug
                    or extract
                    is broken
                    down & again fused together with food grade surfactant under high pressure processing (HPP).</p>
                <p class="pt-2 txt-align">Using D-Sperse , extracts with higher percentage of bio-actives are possible.</p>
            </div>
            <div class="col-md-4">
                <div class="fl-x-cc h-100p">
                    <img src="./assets/d-sperse/d-sperse-tech.png" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-12 mt-3">
                <h3 class="text-primary font-lato-bold mb-3 pt-4 fl-x fl-a-s">
                    Products under
                    <div class="sm-fl-x sm-fl-j-c md-d-block">
                        <img src="./assets/d-sperse/d-sperse-logo.jpg" class="icon-sm">
                    </div>
                </h3>
                <ol class="pl-3  mb-3 ml-4">
                    <li>Curcumin 95% water dispersible
                    </li>
                    <li class="mt-2">AKBA water dispersible</li>
                    <li class="mt-2">Boswellia Seratta water dispersible</li>
                    <li class="mt-2">Lutein water dispersible</li>
                    <li class="mt-2">Coleus Forskolin water dispersible</li>
                    <li class="mt-2">Beta Carotene water dispersible</li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DSperseTechnologyPage'
};
</script>

<style scoped>

</style>
