<template>
    <div class="pt-lg-6 pt-md-5 pt-4 text-center-res pb-lg-6">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="fl-x-res-align-end">
<!--                        <div class="w-50 fl-x-res-align-end">-->
<!--                            <img src="../../assets/home/Landing_page_grow.gif" class="img-fluid" alt="">-->
<!--                        </div>-->
                        <div class="p-lg-5 p-3 mb-3 mb-lg-0 b-orange">
                            <h4 class="mb-3 text-primary text-uppercase font-lato-bold fs-8">Extracting Health From Nature.</h4>
                            <p class="fs-2 txt-align">
                                Arishina Life Sciences Pvt. Ltd. is an
                                export-oriented unit conceived with the objective of processing spices and herbs into value-added forms like oleoresins, essential oils, granules, crystals and to market these value-added products in the International market.  Arishina has made a brand name for itself in the nutraceutical and dietary supplement industry in India as well as abroad. The company caters its range of products to customers in the food, flavor, pharmaceutical, and perfumery industries.
                                The product offering
                                from the company includes nutraceutical ingredients,
                                spice extracts, essential oils, natural antioxidants,
                                and natural colors. The company is listed among fast-growing
                                Indian enterprises that focus on delivering result-based
                                , efficacy-proven, natural nutritional choices to its
                                global clientele.
                                The company aims at creating a sustainable community regime that
                                promotes and protects the life and interests of farmers in India.
                                Arishina follows the ‘Rain Forest Alliance’, a collective movement
                                promoted globally ensure the symbiotic co-existence of farmers,
                                companies, foresters, and consumers with a vision to produce
                                harmonious and sustainable goods. Apart from this, the company has been
                                adhering to ‘Regenerative Agricultural Practices(RAP), a global
                                approach to  improve soil fertility by increasing its organic composition.

                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ShortInfoSection'
};
</script>

<style scoped>

</style>
